import React from 'react';
import layoutReducer, { LayoutAction } from './layout-reducer';
import { getLocalStorageData } from '../../shared/utils/localStorage';
import { CONTENT_FILTERS_OPEN } from '../../shared/data/constants';

export type ToolbarTitle = {
  title: string,
  desktopTitle?: string,
  avatar?: string,
  previousPath?: string,
};

export type LayoutState = {
  filtersOpen: boolean,
  hasFilters?: boolean,
  title?: ToolbarTitle,
};

function getInitialLayoutState(): LayoutState {
  return {
    filtersOpen: getLocalStorageData<boolean>(CONTENT_FILTERS_OPEN, 'boolean') || false,
    hasFilters: false,
  };
};

type Context = {
  state: LayoutState,
  dispatch: React.Dispatch<LayoutAction>,
}

export const LayoutContext = React.createContext({} as Context);

type LayoutProviderProps = {
  children: React.ReactNode,
};

const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [state, dispatch] = React.useReducer(layoutReducer, getInitialLayoutState());

  return (
    <LayoutContext.Provider value={{ state: state, dispatch: dispatch }} >
      {children}
    </LayoutContext.Provider>
  )
};

export default LayoutProvider;
