import React, { useMemo } from 'react';
import clsx from 'clsx';
import { NavLink, useRouteMatch } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import grey from '@material-ui/core/colors/grey';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Route } from '../routes/routes';
import { getAlertsName } from '../auth/UserProfile';
import { AuthContext } from '../auth/store/auth-context';

export const drawerWidth = 220;

const secondaryColor = 'white';

const useStyles = makeStyles((theme) => ({
  listLink: {
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    color: grey[400],
    transition: theme.transitions.create(['color']),
    '&:hover': {
      color: secondaryColor,
    },
  },
  listGroup: {
    color: secondaryColor,
    paddingTop: 8,
    paddingBottom: 8,
  },
  selectedListLink: {
    backgroundColor: 'rgba(0, 0, 0, .4)',
    borderLeftColor: secondaryColor,
    color: secondaryColor,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  listIcon: {
    color: 'inherit',
    minWidth: 40,
  },
}));

type NavigationRouteProps = {
  route: Route,
};

function NavigationRoute({ route }: NavigationRouteProps) {
  const classes = useStyles();
  const match = useRouteMatch();
  const { state } = React.useContext(AuthContext);
  const title = useMemo(() => route.title == 'Alerts' ? `${getAlertsName(state.workspace?.autoTheft == true)}s` : route.title, [route, state.workspace]);

  return (
    <>
      {route.group &&
        (
          <ListItem className={classes.listGroup}>
            <ListItemText primary={route.group} />
          </ListItem>
        )
      }
      <ListItem
        button
        component={NavLink}
        to={route.path}
        exact={route.exact}
        activeClassName={classes.selectedListLink}
        className={clsx(classes.listLink, {
          [classes.selectedListLink]: match.path === route.navigationRouteMatch,
        })}
      >
        {route.icon &&
          <ListItemIcon
            className={classes.listIcon}
            children={<route.icon />}
          />
        }
        <ListItemText primary={title} />
      </ListItem>
    </>
  );
}

export default NavigationRoute;
