export function joinStringsWithSpace(...values: (string | null | undefined)[]) {
    return joinStrings(' ', ...values);
}

export function joinStrings(separator: string, ...values: (string | null | undefined)[]) {
    return values.map(value => value?.trim()).filter(Boolean).join(separator);
}

export function isEmptyString(value?: string | null) {
    return value === null || value === undefined || value === '';
}

export function trimAndNullEmpty(value?: string | null) {
    if (value === null || value === undefined) {
        return null;
    }
    const newValue = value.trim();
    return newValue !== '' ? newValue : null;
}
