import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useDebounce } from '../../utils/effects';

function getAutoComplete(name: string): string {
  switch (name) {
    case 'email':
      return 'email';
    default:
      return '';
  }
};

type Type = 'password' | 'text' | 'number';

type AppInputProps = {
  value: string,
  name: string,
  label: string,
  onChange: (value: string) => void,
  error?: boolean,
  helperText?: string,
  autoFocus?: boolean,
  required?: boolean,
  debounce?: number,
  type?: Type,
  placeholder?: string | null,
  className?: string,
  disabled?: boolean,
};

function AppInput({
  value,
  name,
  label,
  error,
  helperText,
  onChange,
  autoFocus,
  required,
  debounce = 300,
  placeholder = 'Empty',
  type = 'text',
  className,
  disabled,
}: AppInputProps) {
  const [input, setInput] = React.useState(value);
  const debouncedInput = useDebounce(input, debounce);

  React.useEffect(
    () => setInput(value),
    [value]
  );

  React.useEffect(
    () => onChange(debouncedInput),
    [debouncedInput]
  );

  return (
    <TextField
      variant="outlined"
      fullWidth
      id={name}
      label={label}
      name={name}
      autoComplete={getAutoComplete(name)}
      type={type}
      error={error}
      helperText={helperText}
      value={input}
      onChange={e => setInput(e.target.value)}
      autoFocus={autoFocus}
      required={required}
      size="small"
      placeholder={placeholder || undefined}
      className={className}
      InputLabelProps={{
        shrink: placeholder !== undefined ? true : undefined,
      }}
      disabled={disabled}
    />
  );
}

export default AppInput;
