import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LayersIcon from '@material-ui/icons/Layers';
import PeopleIcon from '@material-ui/icons/People';
import LoadableDashboard from '../pages/dashboard/LoadableDashboard';
import LoadableAlerts from '../pages/alerts/LoadableAlerts';
import LoadableUsers from '../pages/users/LoadableUsers';
import LoadableWorkspaces from '../pages/workspaces/LoadableWorkspaces';
import LoadableGroups from '../pages/groups/LoadableGroups';
import LoadableSuspects from '../pages/suspects/LoadableSuspects';
import LoadableVehicles from '../pages/vehicles/LoadableVehicles';
import LoadableAccountSettings from '../pages/account/settings/LoadableAccountSettings';
import accountSettingsRoutes from '../pages/account/settings/accountSettingsRoutes';
import { AppIcon } from '../shared/display/graphic/ActionBadge';
import LoadableAccountProfile from '../pages/account/profile/LoadableAccountProfile';
import { ROUTE_PATH } from '../shared/data/constants';
import LoadableLogin from '../auth/login/LoadableLogin';
import LoadableResetPassword from '../auth/resetPassword/LoadableResetPassword';
import LoadableForgotPassword from '../auth/forgotPassword/LoadableForgotPassword';
import LoadableFaq from '../pages/faq/LoadableFaq';
import LoadableNotifications from '../pages/notifications/LoadableNotifications';
import LoadableReports from '../pages/reports/LoadableReports';

export type RouteParams = {
  id: string,
};

export type RouteInfo = {
  path: string,
  title: string,
};

export type Route = {
  navigationRouteMatch?: string,
  content: React.ComponentType<React.ReactNode>,
  icon?: React.ComponentType<React.ReactNode>,
  exact?: boolean,
  group?: string,
  hide?: boolean,
  children?: Route[],
  avatarIcon?: AppIcon,
  onlyAllowForAutoTheft?: boolean,
  allowedRoles?: string[],
} & RouteInfo;

export const publicRoutes: Route[] = [
  {
    path: '/login',
    title: 'Login',
    exact: true,
    hide: true,
    content: () => <LoadableLogin />,
  },
  {
    path: '/account/reset-password',
    title: 'Reset Password',
    exact: true,
    hide: true,
    content: () => <LoadableResetPassword />,
  },
  {
    path: '/account/forgot-password',
    title: 'Forgot Password',
    exact: true,
    hide: true,
    content: () => <LoadableForgotPassword />,
  },
];

const routes: Route[] = [
  {
    path: '/',
    title: 'Dashboard',
    exact: true,
    icon: () => <DashboardIcon />,
    content: () => <LoadableDashboard />,
    group: 'Reports',
  },
  {
    path: '/alerts',
    title: 'Alerts',
    exact: false,
    icon: () => <WarningIcon />,
    content: () => <LoadableAlerts />,
  },
  {
    path: '/suspects',
    title: 'Suspects',
    exact: false,
    icon: () => <DirectionsRunIcon />,
    content: () => <LoadableSuspects />,
  },
  {
    path: '/vehicles',
    title: 'Vehicles',
    exact: false,
    icon: () => <DirectionsCarIcon />,
    content: () => <LoadableVehicles />,
  },
  {
    path: '/workspaces',
    title: 'My Workspaces',
    exact: false,
    icon: () => <LayersIcon />,
    content: () => <LoadableWorkspaces />,
    group: 'Management',
  },
  {
    path: '/groups',
    title: 'Groups',
    exact: false,
    icon: () => <PeopleIcon />,
    content: () => <LoadableGroups />,
  },
  {
    path: '/users',
    title: 'Users',
    exact: false,
    icon: () => <PersonIcon />,
    content: () => <LoadableUsers />,
  },
  {
    path: '/reports',
    title: 'Reports',
    exact: false,
    icon: () => <DashboardIcon />,
    content: () => <LoadableReports />,
    onlyAllowForAutoTheft: true,
  },
  {
    path: '/account/settings',
    title: 'Account Settings',
    exact: false,
    content: () => <LoadableAccountSettings />,
    hide: true,
    children: accountSettingsRoutes
  },
  {
    path: ROUTE_PATH.ACCOUNT_PROFILE,
    title: 'Account Profile',
    exact: false,
    content: () => <LoadableAccountProfile />,
    hide: true
  },
  {
    path: '/faq',
    title: 'Frequently Asked Questions',
    exact: true,
    content: () => <LoadableFaq />,
    hide: true,
  },
  {
    path: '/notifications',
    title: 'Notifications',
    exact: true,
    content: () => <LoadableNotifications />,
    hide: true,
  },
];

export default routes;
