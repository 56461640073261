import React from 'react';
import { Route, RouteInfo } from '../../../routes/routes';
import { ROUTE_PATH } from '../../../shared/data/constants';
import LoadablePersonalInfo from './personalInfo/LoadablePersonalInfo';
import LoadableSecurity from './security/LoadableSecurity';
import LoadableNotifications from './notifications/LoadableNotifications';

const homeBreadcrumb: RouteInfo = {
  path: ROUTE_PATH.ACCOUNT_SETTINGS,
  title: 'Account Settings',
};

const accountSettingsRoutes: Route[] = [
  {
    path: `${homeBreadcrumb.path}/personal-info`,
    title: 'Personal Info',
    exact: true,
    avatarIcon: 'account_circle',
    content: () => <LoadablePersonalInfo />,
  },
  {
    path: `${homeBreadcrumb.path}/security`,
    title: 'Security',
    exact: true,
    avatarIcon: 'security',
    content: () => <LoadableSecurity />,
  },
  {
    path: `${homeBreadcrumb.path}/notifications`,
    title: 'Notifications',
    exact: true,
    avatarIcon: 'notifications',
    content: () => <LoadableNotifications />,
  },
];

export default accountSettingsRoutes;
