import React from 'react';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { AppIcon } from '../graphic/ActionBadge';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 'auto',
    backgroundColor: 'inherit',
    textAlign: 'center',
  },
  icon: {
    margin: 'auto',
    fontSize: '2.6rem',
    width: 40,
  },
  big: {
    width: 160,
    height: 160,
    fontSize: '10rem',
  },
  fullWidthAvatar: {
    width: '100%',
    height: 200,
    fontSize: '10rem',
    borderRadius: 0,
    borderBottom: theme.app.border,
  },
  medium: {
    width: 80,
    height: 80,
    fontSize: '5rem',
  },
  edit: {
    backgroundColor: '#f4f6f8 !important',
    marginLeft: -10,
    marginTop: -10,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  link: {
    cursor: 'pointer',
  },
  fullWidth: {
    width: '100%',
    borderRadius: 0,
  },
  noBorder: {
    borderRadius: 0,
  },
  empty: {
    borderRadius: 0,
    background: '#e6e4e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
  },
}));

type AvatarSize = 'big' | 'medium' | 'fullWidth';

export type AppAvatarProps = {
  image?: string,
  icon?: AppIcon,
  editable?: boolean,
  fullWidth?: boolean,
  onEdit?: () => void,
  onClick?: () => void,
  avatarSize?: AvatarSize,
};

function AppAvatar({ image, icon, avatarSize, editable, fullWidth, onEdit, onClick }: AppAvatarProps) {
  const classes = useStyles();
  const className = avatarSize == 'big' ? classes.big : avatarSize == 'medium' ? classes.medium : avatarSize == 'fullWidth' ? classes.fullWidthAvatar : undefined;
  const avatar = (
    <Avatar
      src={image}
      className={clsx(classes.avatar, className, {
        [classes.noBorder]: image === undefined,
        [classes.empty]: fullWidth && image === undefined,
        [classes.link]: onClick !== undefined,
        [classes.fullWidth]: fullWidth
      })}
      onClick={onClick}
    >
      <Icon
        className={clsx(classes.icon, className)}
        children={icon}
        color="primary"
      />
    </Avatar>
  );

  if (editable) {
    return (
      <div className={classes.avatar}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <IconButton
              className={classes.edit}
              onClick={onEdit}
              color="inherit"
              size="small"
            >
              <EditIcon />
            </IconButton>
          }
        >
          {avatar}
        </Badge>
      </div>
    );
  }
  return avatar;
}

export default AppAvatar;
