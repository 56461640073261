import React from 'react';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { geocodeByAddress, getGeoResults } from '../../utils/googleMaps';
import { joinStringsWithSpace } from '../../utils/strings';
import { CoordinatesInput } from '../../../__generated__/globalTypes';

export type AppAddress = {
  rawAddress: string,
  name?: string,
  coordinates?: CoordinatesInput,
  address?: string,
  address2?: string,
  city?: string,
  state?: string,
  country?: string,
  countryCode?: string,
  postalCode?: string,
};

type PlaceType = {
  description: string,
  structured_formatting: {
    main_text: string,
    secondary_text: string,
    main_text_matched_substrings: [
      {
        offset: number,
        length: number,
      },
    ],
  },
};

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export function addMissingCoordinates(address: AppAddress, onChange: (address: AppAddress) => void) {
  if (address.rawAddress !== '' && !address.coordinates) {
    geocodeByAddress(address.rawAddress)
      .then(results => getGeoResults(results[0]))
      .then(geoResults => onChange({ ...address, coordinates: geoResults.coordinates }))
      .catch(error => {
        console.error('Error', error);
        onChange(address);
      });
  } else {
    onChange(address);
  }
};

export function loadFullAddress(rawAddress: string, structuredAddress: string | undefined, onChange: (address: AppAddress) => void) {
  return geocodeByAddress(rawAddress)
    .then(results => getGeoResults(results[0]))
    .then(geoResults => {
      onChange({
        name: geoResults.firstComponent && !rawAddress.startsWith(geoResults.firstComponent) ? (structuredAddress ?? geoResults.firstComponent) : undefined,
        rawAddress: geoResults.rawAddress,
        coordinates: geoResults.coordinates,
        address: geoResults.address,
        address2: geoResults.address2,
        city: geoResults.city,
        state: geoResults.state,
        country: geoResults.country,
        countryCode: geoResults.countryCode,
        postalCode: geoResults.postalCode,
      });
    })
    .catch(error => {
      console.error('Error', error);
      onChange({
        rawAddress: rawAddress,
      });
    });
};

type AppPlacesAutocompleteProps = {
  address?: string,
  placeholder?: string,
  onChange: (value: AppAddress) => void,
  required?: boolean,
};

function AppPlacesAutocomplete({ address, placeholder = 'Empty', onChange, required }: AppPlacesAutocompleteProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState<string>();
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    setInputValue(address);
  }, [address]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (!inputValue || inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (event: any, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue) {
      loadFullAddress(newValue.description, newValue.structured_formatting.main_text, onChange);
    } else {
      onChange({ rawAddress: '' });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth
      noOptionsText="Start typing to search..."
      value={value}
      inputValue={inputValue || ''}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Address Search"
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          required={required}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AppPlacesAutocomplete;
