import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AppDialog from '../../../../../../../shared/display/dialog/Dialog';
import { AppAddress, addMissingCoordinates } from '../../../../../../../shared/display/form/PlacesAutocomplete';
import AppAddressInput from '../../../../../../../shared/display/form/AddressInput';
import { isEmptyString, joinStringsWithSpace, joinStrings } from '../../../../../../../shared/utils/strings';
import AppInputWrapper from '../../../../../../../shared/display/form/InputWrapper';
import AppParagraph from '../../../../../../../shared/display/typography/Paragraph';

function isFormValid(data: AppAddress): boolean {
  return !isEmptyString(data.city) && !isEmptyString(data.state) && !isEmptyString(data.postalCode) && !isEmptyString(data.country);
};

type ProfileWorkAddressInputProps = {
  address: AppAddress,
  onChange: (address: AppAddress) => void,
};

function ProfileWorkAddressInput({ address, onChange }: ProfileWorkAddressInputProps) {
  const [open, setOpen] = React.useState(false);
  const [newAddress, setNewAddress] = React.useState<AppAddress>({ rawAddress: '' });

  const handleOnClose = () => {
    setOpen(false);
    setNewAddress({ ...address });
  };

  const handleOnConfirm = () => {
    setOpen(false);
    addMissingCoordinates(newAddress, address => onChange(address));
  };

  React.useEffect(() => {
    setNewAddress({ ...address });
  },
    [address]
  );

  return (
    <>
      <ListItem>
        <AppInputWrapper label="Work Address">
          <div>
            <ListItemText>
              {(!newAddress || newAddress.rawAddress === '') && <AppParagraph text="Empty" />}
              {newAddress?.address && <AppParagraph text={newAddress.address} />}
              {newAddress?.address2 && <AppParagraph text={newAddress.address2} />}
              {newAddress && <AppParagraph text={joinStringsWithSpace(joinStrings(', ', newAddress.city, newAddress.state), newAddress.postalCode)} />}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => setOpen(true)}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </div>
        </AppInputWrapper>
      </ListItem>
      <AppDialog
        open={open}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        title="Change Work Address"
        confirmEnabled={isFormValid(newAddress)}
        notificationType="None"
        disableContentPadding
      >
        <List>
          <AppAddressInput address={newAddress} onChange={(value) => setNewAddress(value)} />
        </List>
      </AppDialog>
    </>
  );
};

export default ProfileWorkAddressInput;
