import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { LayoutContext } from '../store/layout-store';
import PreviousButton from './PreviousButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  avatar: {
    marginRight: 10,
    width: 30,
    height: 30,
  },
}));

function PageTitle() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { state } = React.useContext(LayoutContext);
  const { title } = state;
  const displayTitle = title ? ((!isMobile && title?.desktopTitle ? `${title.desktopTitle} ` : '') + title?.title) : '';

  return (
    <>
      <PreviousButton previousPath={title?.previousPath} />
      <div className={classes.root}>
        {title?.avatar &&
          <Avatar src={title.avatar} className={classes.avatar} />
        }
        <Typography color="primary">
          {displayTitle}
        </Typography>
      </div>
    </>
  );
}

export default PageTitle;
