import React from 'react';
import { PropTypes } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export type AppParagraphProps = {
  text?: string,
  className?: string,
  align?: PropTypes.Alignment,
  children?: React.ReactNode,
  title?: string,
}

function AppParagraph({ text, className, align, children, title }: AppParagraphProps) {
  return (
    <Typography
      variant="body2"
      className={className}
      align={align}
      title={title}
    >
      {text}
      {children}
    </Typography>
  );
}

export default AppParagraph;
