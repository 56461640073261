import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { OverridableStringUnion } from '@material-ui/types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography, { TypographyVariantDefaults, TypographyPropsVariantOverrides } from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import AppParagraph from '../typography/Paragraph';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));

type AppDescriptionBoxProps = {
  title: string,
  value?: string,
  valueHref?: string,
  valueInfo?: string,
  noBorder?: boolean,
  valueVariant?: OverridableStringUnion<TypographyVariantDefaults, TypographyPropsVariantOverrides>,
  children?: React.ReactNode,
  className?: string,
}

function AppDescriptionBox({ title, value, valueHref, valueInfo, noBorder, valueVariant = 'subtitle1', children, className }: AppDescriptionBoxProps) {
  const classes = useStyles();
  const valueComponent = children || (
    <Typography
      variant={valueVariant}
      style={{ fontWeight: 500 }}
      title={valueInfo}
    >
      {value}
    </Typography>
  );

  return (
    <Box
      className={className}
      sx={{
        p: 1,
        flexGrow: 1,
        borderRight: 'solid',
        borderRightWidth: (noBorder ? 0 : 1),
        borderColor: grey[300],
        textAlign: 'center',
      }}
    >
      <div className={classes.container}>
        {valueHref ? (
          <Link
            component={NavLink}
            to={valueHref}
            children={valueComponent}
          />
        ) : valueComponent}
        <AppParagraph text={title} />
      </div>
    </Box>
  );
}

export default AppDescriptionBox;
