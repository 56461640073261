import React from 'react';
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDebounce } from '../../utils/effects';
import AppInputWrapper from '../form/InputWrapper';
import { AppPhoneNumberProps } from './PhoneNumber';
import AppInput from '../form/Input';

const useStyles = makeStyles((theme) => ({
  phone: {
    padding: '8px 0 10px 0',
    '& .PhoneInputInput': {
      border: 0,
      outline: 'none',
    }
  },
  extension: {
    width: 100,
    marginLeft: theme.spacing(2),
    '& input': {
      padding: 8.5,
    }
  },
}));

type AppPhoneNumberInputProps = {
  name: string,
  value: AppPhoneNumberProps | null,
  onChange: (value: AppPhoneNumberProps | null) => void,
  hasExtension?: boolean,
};

function AppPhoneNumberInput({ name, value, onChange, hasExtension }: AppPhoneNumberInputProps) {
  const classes = useStyles();
  const [input, setInput] = React.useState(value ? formatPhoneNumberIntl(`+${value.countryCode}${value.phoneNumber}`) : '');
  const [extension, setExtension] = React.useState(value ? value.extension || '' : '');
  const debouncedInput = useDebounce(input, 150);
  const debouncedExtension = useDebounce(extension, 150);

  const handleChange = () => {
    const parsed = debouncedInput && debouncedInput !== '' ? parsePhoneNumber(debouncedInput) : null;
    onChange(parsed ? {
      countryCode: parsed.countryCallingCode,
      phoneNumber: parsed.nationalNumber,
      extension: extension,
    } : null);
  };

  React.useEffect(
    () => handleChange(),
    [debouncedInput]
  );

  React.useEffect(
    () => handleChange(),
    [debouncedExtension]
  );

  return (
    <>
      <AppInputWrapper label={name}>
        <PhoneInput
          className={classes.phone}
          placeholder="Empty"
          defaultCountry="US"
          value={input}
          onChange={setInput}
          international={false}
        />
      </AppInputWrapper>
      {
        hasExtension &&
        <AppInput
          className={classes.extension}
          name="extension"
          label="Ext"
          placeholder="None"
          value={extension}
          onChange={(value) => setExtension(value)}
          type="number"
        />
      }
    </>
  );
};

export default AppPhoneNumberInput;
