import React from 'react';
import AppMenu, { AppMenuOption } from '../../../../../shared/display/menu/Menu';
import EditUser from './edit/EditUser';
import { analyticsTrackUsers } from '../../../../../shared/utils/googleAnalytics';
import { UserDirectoryQuery_user } from '../../__generated__/UserDirectoryQuery';

type MenuActions = 'edit';

const menuOptions: AppMenuOption<MenuActions>[] = [{ id: 'edit', name: 'Edit User' }];

type UserActionsMenuProps = {
  user: UserDirectoryQuery_user,
  onUpdated: () => void,
};

function UserActionsMenu({ user, onUpdated }: UserActionsMenuProps) {
  const [dialogType, setDialogType] = React.useState<MenuActions | null>(null);

  const handleOpen = (type: MenuActions | null) => {
    switch (type) {
      case 'edit':
        analyticsTrackUsers('open_edit');
        break;
    }
    setDialogType(type);
  };

  const handleClose = () => {
    switch (dialogType) {
      case 'edit':
        analyticsTrackUsers('close_edit');
        break;
    }
    setDialogType(null);
  };

  const handleConfirm = () => {
    setDialogType(null);
    onUpdated();
  };

  return (
    <>
      <AppMenu
        options={menuOptions}
        onSelected={handleOpen}
        isSmall
      />
      {dialogType === 'edit' &&
        <EditUser
          user={user}
          onClose={handleClose}
          onUpdated={handleConfirm}
        />
      }
    </>
  );
}

export default UserActionsMenu;
