import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { apolloLink } from '../../../shared/data/apolloClient';

const alertsContentApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Alert: {
        fields: {
          attachments: relayStylePagination(),
          comments: relayStylePagination(),
          followers: relayStylePagination(),
        },
      },
      Organization: {
        fields: {
          alerts: relayStylePagination(),
          suspects: relayStylePagination(),
          vehicles: relayStylePagination(),
          memberships: relayStylePagination(),
        },
      },
    },
  }),
});

export default alertsContentApolloClient;
