import React from 'react';
import { gql } from '@apollo/client';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import AppPhoneNumber from '../../../../shared/display/phone/PhoneNumber';
import { AppAddress, loadFullAddress } from '../../../../shared/display/form/PlacesAutocomplete';
import { UserProfileData } from './__generated__/UserProfileData';
import ContactInfo from './ContactInfo';
import AppTreuthUpload from '../../../../shared/display/media/TreuthUpload';
import UserActionsMenu from './actions/UserActionsMenu';
import * as countries from 'i18n-iso-countries';
import countryDataEn from 'i18n-iso-countries/langs/en.json';
import { UserDirectoryQuery_user } from '../__generated__/UserDirectoryQuery';
countries.registerLocale(countryDataEn);

export function updateUserAddress(user: UserProfileData, onChange: (address: AppAddress) => void, disableFullAddressLoad: boolean = false) {
  if (user.address) {
    const country = user.address.country ? countries.getName(user.address.country, 'en', { select: 'alias' }) : null;
    onChange({
      rawAddress: user.workAddress || '',
      address: user.address.address || '',
      address2: user.address.address2 || '',
      city: user.address.city || '',
      state: user.address.state || '',
      country: country || user.address.country || '',
      countryCode: user.address.country || '',
      postalCode: user.address.postalCode || '',
    });
  } else if (user.workAddress) {
    if (disableFullAddressLoad) {
      onChange({ rawAddress: user.workAddress });
    } else {
      loadFullAddress(user.workAddress, user.workAddress, onChange);
    }
  } else {
    onChange({ rawAddress: '' });
  }
};

Profile.fragments = {
  details: gql`
    fragment UserProfileData on User {
      id,
      firstName,
      lastName,
      email,
      platformRole,
      position,
      region,
      company,
      workAddress,
      mobilePhoneNumber {
        ...PhoneNumberDetails,
      },
      officePhoneNumber {
        ...PhoneNumberDetails,
      },
      photo {
        ...UploadDetails,
      },
      address {
        id,
        address,
        address2,
        address3,
        city,
        state,
        postalCode,
        country,
      },
      primaryOrganization {
        id,
        name,
      },
      passwordUpdatedDate,
      sms,
    }  
    ${AppPhoneNumber.fragments.details},
    ${AppTreuthUpload.fragments.details},
    `,
};

const useStyles = makeStyles(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  headerAction: {
    marginTop: -11,
  },
  noCardBorder: {
    borderBottom: 'none !important',
  }
}));

type ProfileProps = {
  isPlatformAdmin: boolean,
  isWorkspaceAdmin: boolean,
  isLoggedInUser: boolean,
  user: UserDirectoryQuery_user,
  onUpdated: () => void,
  disableHeader?: boolean,
};

function Profile({ isPlatformAdmin, isWorkspaceAdmin, isLoggedInUser, user, onUpdated, disableHeader }: ProfileProps) {
  const classes = useStyles();
  const [workAddress, setWorkAddress] = React.useState<AppAddress>({ rawAddress: '' });
  const headerAction = (isPlatformAdmin || isWorkspaceAdmin || isLoggedInUser) ? (<UserActionsMenu user={user} onUpdated={onUpdated} />) : null;

  React.useEffect(() => {
    updateUserAddress(user, setWorkAddress, true);
  },
    [user]
  );

  return (
    <Card className={clsx({ [classes.noCardBorder]: disableHeader })}>
      {!disableHeader && <CardHeader
        title="About"
        action={headerAction}
        classes={
          {
            action: classes.headerAction,
          }
        }
      />}
      <CardContent className={classes.cardContent}>
        <List disablePadding>
          {user.position &&
            <ContactInfo name="Position" value={user.position} />
          }
          {user.region &&
            <ContactInfo name="Region" value={user.region} />
          }
          {user.mobilePhoneNumber &&
            <ContactInfo
              name="Mobile Phone"
              value="Phone"
              phone={user.mobilePhoneNumber}
              type="phone"
            />
          }
          {user.officePhoneNumber &&
            <ContactInfo
              name="Office Phone"
              value="Phone"
              phone={user.officePhoneNumber}
              type="phone"
            />
          }
          <ContactInfo
            name="Email"
            value={user.email}
            type="email"
          />
          {user.workAddress &&
            <ContactInfo
              name="Work Address"
              type="address"
              value={user.workAddress}
              address={workAddress}
            />
          }
        </List>
      </CardContent>
    </Card>
  );
}

export default Profile;
