import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../auth/store/auth-context';
import { authLogout } from '../../auth/store/auth-actions';
import { AppValue } from '../../shared/data/types';
import ChangeWorkspace from './ChangeWorkspace';
import { joinStringsWithSpace } from '../../shared/utils/strings';
import AppAvatar from '../../shared/display/media/Avatar';
import { analyticsTrackProfile } from '../../shared/utils/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: 40,
    marginRight: theme.spacing(2),
  },
}));

function ProfileMenu() {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [workspaces, setWorkspaces] = React.useState<AppValue[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const open = Boolean(anchorEl);
  const globalTheme = useTheme();
  const isMobile = useMediaQuery(globalTheme.breakpoints.down('sm'));

  React.useEffect(
    () => {
      setWorkspaces(state.workspaces || []);
    },
    [state]
  );

  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    authLogout(dispatch);
    handleClose();
  };
  const handleChangeWorkspace = () => {
    analyticsTrackProfile('open_change_workspace_menu');
    setOpenDialog(true);
    handleClose();
  };
  if (!state.user) {
    return <></>;
  }
  return (
    <>
      {!isMobile && (
        <IconButton color="inherit" component={Link} to="/notifications">
          <NotificationsIcon />
        </IconButton>
      )}
      <IconButton
        onClick={event => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
      >
        <MenuItem onClick={handleClose} component={Link} to={`/users/${state.user.id}`}>
          <ListItemAvatar className={classes.avatar}>
            <AppAvatar
              image={state.user.photo?.url}
              icon="account_circle"
            />
          </ListItemAvatar>
          <ListItemText
            primary={joinStringsWithSpace(state.user.firstName, state.user.lastName)}
            secondary="See your profile"
          />
        </MenuItem>
        <Divider />
        {workspaces.length > 1 &&
          <MenuItem onClick={handleChangeWorkspace}>Change Workspace</MenuItem>
        }
        <MenuItem component={Link} to="/account/settings">Account Settings</MenuItem>
        <MenuItem component={Link} to="/faq">FAQ</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      {state.workspaceId &&
        <ChangeWorkspace
          open={openDialog}
          workspaceId={state.workspaceId}
          workspaces={workspaces}
          onClose={() => setOpenDialog(false)}
        />
      }
    </>
  );
}

export default ProfileMenu;
