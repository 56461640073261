import React from 'react';
import { gql } from '@apollo/client';
import Link from '@material-ui/core/Link';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import AppCardTitle from '../typography/CardTitle';

AppPhoneNumber.fragments = {
  details: gql`
    fragment PhoneNumberDetails on PhoneNumber {
      countryCode,
      phoneNumber,
      extension,
    }
    `,
};

export type AppPhoneNumberProps = {
  countryCode: string,
  phoneNumber: string,
  extension: string | null,
}

function AppPhoneNumber({ countryCode, phoneNumber, extension }: AppPhoneNumberProps) {
  let value = formatPhoneNumberIntl(`+${countryCode}${phoneNumber}`);
  if (extension) {
    value += `, ext. ${extension}`;
  }
  return (
    <Link href={`tel:${countryCode}${phoneNumber}${extension ? ',' + extension : ''}`}>
      <AppCardTitle text={value} />
    </Link>
  );
};

export default AppPhoneNumber;
