import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import Layout from '../layout/Layout';
import routes, { publicRoutes } from './routes';
import AppRoute from './AppRoute';
import { analyticsPageView } from '../shared/utils/googleAnalytics';
import LoadableAlertPrint from '../pages/alertPrint/LoadableAlertPrint';
import LoadableAutoTheftReportPrint from '../pages/reports/autoTheft/print/LoadableAutoTheftReportPrint';
import RouterProvider from './store/route-store';

function AppRoutes() {
  const { pathname } = useLocation();

  useEffect(
    () => {
      let name = 'Treuth';
      let found = false;
      routes.some(route => {
        if (route.path === pathname) {
          name = route.title;
          found = true;
        } else if (route.children) {
          route.children.some(childRoute => {
            if (childRoute.path === pathname) {
              name = childRoute.title;
              found = true;
              return true;
            }
            return false;
          });
        } else if (route.path !== '/' && pathname.startsWith(route.path)) {
          name = route.title + ' Details';
          found = true;
        }
        return found;
      });
      if (!found) {
        publicRoutes.some(route => {
          if (route.path === pathname) {
            name = route.title;
            found = true;
          }
          return found;
        });
      }
      analyticsPageView(pathname, name);
    },
    [pathname]
  );

  return (
    <RouterProvider>
      <Switch>
        <Route
          path="/alerts/:id/print"
          exact={true}
          component={() => <LoadableAlertPrint />}
        />
        <Route
          path="/reports/AutoTheft/print/:year/:month"
          exact={true}
          component={() => <LoadableAutoTheftReportPrint />}
        />
        {publicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.content}
          />
        ))}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        {routes.map((route, index) => (
          <AppRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.content}
            layout={Layout}
          />
        ))}
        <Redirect to="/" />
      </Switch>
    </RouterProvider>
  );
}

export default AppRoutes;
