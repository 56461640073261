import { CoordinatesInput } from '../../__generated__/globalTypes';
import { joinStringsWithSpace } from './strings';

type GeoResults = {
    coordinates: CoordinatesInput,
    rawAddress: string,
    firstComponent?: string,
    address?: string,
    address2?: string,
    city?: string,
    state?: string,
    country?: string,
    countryCode?: string,
    postalCode?: string,
};

export const getGeoResults = (result: google.maps.GeocoderResult): Promise<GeoResults> => (
    new Promise((resolve, reject) => {
        try {
            let postalCode: string | undefined = undefined;
            let country: string | undefined = undefined;
            let countryCode: string | undefined = undefined;
            let state: string | undefined = undefined;
            let city: string | undefined = undefined;
            let streetNumber: string | undefined = undefined;
            let address: string | undefined = undefined;
            let address2: string | undefined = undefined;
            result.address_components.forEach((address_component) => {
                if (address_component.types.indexOf('postal_code') !== -1) {
                    postalCode = address_component.long_name;
                }
                if (address_component.types.indexOf('country') !== -1) {
                    country = address_component.long_name;
                    countryCode = address_component.short_name;
                }
                if (address_component.types.indexOf('administrative_area_level_1') !== -1) {
                    state = address_component.short_name;
                }
                if (address_component.types.indexOf('locality') !== -1) {
                    city = address_component.long_name;
                }
                if (address_component.types.indexOf('street_number') !== -1) {
                    streetNumber = address_component.long_name;
                }
                if (address_component.types.indexOf('route') !== -1) {
                    address = address_component.short_name;
                }
                if (address_component.types.indexOf('subpremise') !== -1) {
                    address2 = address_component.long_name;
                }
            });
            return resolve({
                coordinates: {
                    latitude: result.geometry.location.lat(),
                    longitude: result.geometry.location.lng(),
                },
                rawAddress: result.formatted_address,
                firstComponent: result.address_components[0].long_name,
                address: joinStringsWithSpace(streetNumber, address),
                address2: address2,
                city: city,
                state: state,
                country: country,
                countryCode: countryCode,
                postalCode: postalCode,
            });
        } catch (e) {
            return reject(e);
        }
    })
);

export const geocodeByPlaceId = (placeId: string): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;

    return new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
            if (status !== OK) {
                return reject(status);
            }
            return resolve(results);
        });
    });
};


export const geocodeByAddress = (address: string): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;

    return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status !== OK) {
                return reject(status);
            }

            return resolve(results);
        });
    });
};