import React, { Suspense } from 'react';

const LoadableComponent = React.lazy(() => import('./AutoTheftReportPrint'));

function LoadableAutoTheftReportPrint() {
  return (
    <Suspense fallback={<div />}>
      <LoadableComponent />
    </Suspense>
  );
}

export default LoadableAutoTheftReportPrint;
