import React from 'react';
import AppDialog from '../../shared/display/dialog/Dialog';
import { AppValue } from '../../shared/data/types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AuthContext } from '../../auth/store/auth-context';
import { analyticsTrackProfile } from '../../shared/utils/googleAnalytics';

type ChangeWorkspaceProps = {
  open: boolean,
  workspaceId: number,
  workspaces: AppValue[],
  onClose: () => void,
};

function ChangeWorkspace({ open, workspaceId, workspaces, onClose }: ChangeWorkspaceProps) {
  const [selected, setSelected] = React.useState<number>(workspaceId);
  const { dispatch } = React.useContext(AuthContext);

  const handleOnConfirm = () => {
    analyticsTrackProfile('change_workspace', workspaces.find(item => item.id === selected)?.name);
    dispatch(
      {
        type: 'UPDATE_WORKSPACE',
        payload: selected,
      }
    );
  };

  React.useEffect(
    () => {
      setSelected(workspaceId);
      onClose();
    },
    [workspaceId]
  );

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      title="Change Workspace"
      confirmEnabled={selected !== workspaceId}
      notificationType="None"
    >
      <RadioGroup
        name="workspace"
        value={selected}
        onChange={(event) => setSelected(Number(event.target.value))}
      >
        {
          workspaces.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.id}
              control={<Radio />}
              label={item.name}
            />
          ))
        }
      </RadioGroup>
    </AppDialog>
  );
}

export default ChangeWorkspace;
