import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  border: {
    borderTop: theme.app.border,
  },
}));

export type AppMenuOption<T> = {
  id: T,
  name: string,
  hasDivider?: boolean,
}

export type AppMenuActions<T> = {
  onSelected: (id: T) => void,
}

type AppMenuProps<T> = {
  options: AppMenuOption<T>[],
  className?: string,
  isSmall?: boolean,
  icon?: React.ReactNode,
  fab?: boolean,
}

function AppMenu<T>({ options, onSelected, className, isSmall, icon, fab }: AppMenuProps<T> & AppMenuActions<T>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const size = isSmall ? 'small' : 'medium';
  const actionIcon = icon ? icon : <MoreVertIcon />;
  const button = fab ?
    <Fab className={className} color="primary" onClick={handleOpen} size={size} children={actionIcon} /> :
    <IconButton className={className} onClick={handleOpen} size={size} children={actionIcon} />;

  return (
    <>
      {button}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {
          options.map((option, index) => (
            <MenuItem
              key={index}
              component="label"
              className={clsx({ [classes.border]: option.hasDivider })}
              onClick={() => {
                onSelected(option.id);
                setAnchorEl(null);
              }}
            >
              {option.name}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
}

export default AppMenu;
