import { gql, useQuery } from '@apollo/client';
import { AppListItemValue } from '../../../shared/display/list/CheckboxList';
import { joinStrings, joinStringsWithSpace } from '../../../shared/utils/strings';
import AppTreuthUpload, { findFirstImage } from '../../../shared/display/media/TreuthUpload';
import { AppLinkListItem } from '../../../shared/display/list/LinkList';
import { SuspectInfo } from './__generated__/SuspectInfo';
import { WorkspaceSuspectsListQuery, WorkspaceSuspectsListQueryVariables } from './__generated__/WorkspaceSuspectsListQuery';
import { SuspectRace, SuspectSex } from '../../../__generated__/globalTypes';
import { startCase } from 'lodash';
import useSuspectsQuery from '../../workspaces/details/cards/suspectsQuery';

useSuspectsListQuery.fragments = {
  info: gql`
    fragment SuspectInfo on Suspect {
      id,
      firstName,
      middleName,
      lastName,
      address,
      knownOffenderStatus,
      description,
      sex,
      race
    }
    `,
}

const WORKSPACE_SUSPECTS_QUERY = gql`
query WorkspaceSuspectsListQuery($workspaceId: Int!, $cursor: String, $query: String) {
  organization(id: $workspaceId) {
    id,
    suspects(first: 5, after: $cursor, orderBy: { createdDate: DESC}, where: { query: $query }) @connection(key: "organization") {
      edges {
        node {
          ...SuspectInfo,
          attachments(first: 5, orderBy: { createdDate: DESC }) {
            edges {
              node {
                id,
                upload {
                  ...UploadMedia,
                },
              },
            },
          },
          primaryAttachment {
            id,
            upload {
              ...UploadMedia,
            },
          },
        },
      },
      pageInfo {
        endCursor,
        hasNextPage,
      },
    },
  }
},
${useSuspectsListQuery.fragments.info},
${AppTreuthUpload.fragments.media},  
`;

export function suspectToListItem(suspect: SuspectInfo, avatarImage?: string): AppListItemValue {
  const race = suspect.race !== SuspectRace.UNKNOWN ? startCase(suspect.race.toLowerCase()) : null;
  const sex = suspect.sex !== SuspectSex.UNKNOWN ? startCase(suspect.sex.toLowerCase()) : null;
  const info = joinStringsWithSpace(race, sex);
  return {
    id: suspect.id,
    title: `${suspect.id} - ` + (joinStringsWithSpace(suspect.firstName, suspect.middleName, suspect.lastName) || 'Unknown'),
    subheader: joinStrings(': ', info, suspect.description),
    avatarImage: avatarImage,
    avatarIcon: 'account_circle',
  };
}

export function suspectToListLinkItem(suspect: SuspectInfo, avatarImage?: string, data: any = undefined): AppLinkListItem {
  var info = suspectToListItem(suspect, avatarImage);
  return {
    id: info.id,
    href: `/suspects/${suspect.id}`,
    title: info.title,
    subheader: info.subheader,
    avatarImage: info.avatarImage,
    avatarIcon: info.avatarIcon,
    data,
  };
}

function useSuspectsListQuery(workspaceId: number, query: string) {
  const { data, loading, error, fetchMore, refetch } = useQuery<WorkspaceSuspectsListQuery, WorkspaceSuspectsListQueryVariables>(
    WORKSPACE_SUSPECTS_QUERY, { variables: { workspaceId, query } },
  );

  let fetching = loading || data === undefined;
  let hasNextPage = data?.organization.suspects.pageInfo.hasNextPage || false;
  let suspects: AppListItemValue[] = (data?.organization.suspects.edges || [])
    .map(edge => {
      const image = edge.node.primaryAttachment ? edge.node.primaryAttachment.upload.url : findFirstImage(edge.node.attachments.edges.map(edge => edge.node.upload));
      return suspectToListItem(edge.node, image);
    });

  const loadMore = () => {
    fetchMore({
      variables: {
        cursor: data?.organization.suspects.pageInfo.endCursor,
      },
    });
  };

  return {
    suspects,
    fetching,
    error,
    refetch,
    loadMore,
    hasNextPage,
  };
}

export default useSuspectsListQuery;
