/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Types of links described as sourceAlert ${link type} targetAlert
 */
export enum AlertLinkType {
  IS_PARENT_OF = "IS_PARENT_OF",
  IS_RELATED_TO = "IS_RELATED_TO",
  RECOVERY_OF = "RECOVERY_OF",
}

/**
 * Available statuses for an alert. Defaults to PENDING on creation.
 */
export enum AlertStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  PENDING = "PENDING",
}

/**
 * Suspect age classification for a given arrest record
 */
export enum ArrestAge {
  ADULT = "ADULT",
  JUVENILE = "JUVENILE",
  UNKNOWN = "UNKNOWN",
}

/**
 * A user role per group, values include admin and user
 */
export enum GroupMembershipRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

/**
 * Available statuses for a group. Defaults to ACTIVE on creation
 */
export enum GroupStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

/**
 * Whether an offender is a known repeat offender
 */
export enum KnownOffenderStatus {
  ARMED_OFFENDER = "ARMED_OFFENDER",
  CHRONIC_OFFENDER = "CHRONIC_OFFENDER",
  JUVENILE_OFFENDER = "JUVENILE_OFFENDER",
  KNOWN_ORC = "KNOWN_ORC",
  NOT_ORC = "NOT_ORC",
  ORGANIZED_CRIME_OFFENDER = "ORGANIZED_CRIME_OFFENDER",
  PROBABLE_ORC = "PROBABLE_ORC",
  UNKNOWN = "UNKNOWN",
  VIOLENT_OFFENDER = "VIOLENT_OFFENDER",
}

export enum OrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * A user role per organization, values include admin and user
 */
export enum OrganizationMembershipRole {
  ADMIN = "ADMIN",
  ANALYST = "ANALYST",
  USER = "USER",
}

/**
 * Platform-wide role above organizations
 */
export enum PlatformRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

/**
 * Race of suspect
 */
export enum SuspectRace {
  ALASKA_NATIVE = "ALASKA_NATIVE",
  AMERICAN_INDIAN = "AMERICAN_INDIAN",
  ASIAN = "ASIAN",
  BLACK_OR_AFRICAN_AMERICAN = "BLACK_OR_AFRICAN_AMERICAN",
  HISPANIC_OR_LATINO = "HISPANIC_OR_LATINO",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
  UNKNOWN = "UNKNOWN",
  WHITE = "WHITE",
}

/**
 * Sex of suspect
 */
export enum SuspectSex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

export interface AddressInput {
  address?: string | null;
  address2?: string | null;
  address3?: string | null;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  coordinates: CoordinatesInput;
  timezone?: string | null;
}

export interface AlertInput {
  description?: string | null;
  eventDate?: any | null;
  subcategoryIds?: number[] | null;
  location?: LocationInput | null;
  estimatedValue?: EstimatedValueInput | null;
  agency?: string | null;
  case?: string | null;
  authorId?: number | null;
  assigneeId?: number | null;
}

export interface ArrestInput {
  arrestAge?: string | null;
}

export interface CoordinatesInput {
  longitude: number;
  latitude: number;
}

export interface CreateAlertsEntryInput {
  key: string;
  alert: AlertInput;
  vehicles: CreateAlertsEntryVehicleInput[];
}

export interface CreateAlertsEntryVehicleInput {
  vehicle: VehicleInput;
  recoveredVehicle?: RecoveredVehicleInput | null;
}

export interface CreateAlertsInput {
  organizationId: number;
  alerts: CreateAlertsEntryInput[];
}

export interface EstimatedValueInput {
  amount: number;
  currencyCode: string;
}

export interface LocationInput {
  name?: string | null;
  address?: string | null;
  type?: string | null;
  coordinates?: CoordinatesInput | null;
}

export interface NearbyInput {
  longitude: number;
  latitude: number;
  radius: number;
}

export interface PhoneNumberInput {
  countryCode: string;
  phoneNumber: string;
  extension?: string | null;
}

export interface RecoveredVehicleInput {
  fled?: boolean | null;
  keys?: boolean | null;
  processed?: boolean | null;
  ignitionPunched?: boolean | null;
  shavedKey?: boolean | null;
  burned?: boolean | null;
  stripped?: boolean | null;
  vinRemovedTampered?: boolean | null;
  permanentTheft?: boolean | null;
  rental?: boolean | null;
  alprAssisted?: boolean | null;
}

export interface SuspectInput {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  address?: string | null;
  dateOfBirth?: any | null;
  description?: string | null;
  knownOffenderStatus?: KnownOffenderStatus | null;
  sex?: SuspectSex | null;
  race?: SuspectRace | null;
}

export interface UpdateArrestInput {
  id: number;
  updatedFields: ArrestInput;
}

export interface UpdateRecoveredVehicleInput {
  id: number;
  updatedFields: RecoveredVehicleInput;
}

export interface VehicleInput {
  make?: string | null;
  model?: string | null;
  year?: number | null;
  color?: string | null;
  registrationPlate?: VehicleRegistrationPlateInput | null;
  description?: string | null;
  vin?: string | null;
}

export interface VehicleRegistrationPlateInput {
  jurisdiction?: string | null;
  identifier?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
