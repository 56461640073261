import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { PlatformRole } from '../../../../../__generated__/globalTypes';
import AppInput from '../../../../../shared/display/form/Input';
import AppSelect from '../../../../../shared/display/select/Select';
import { workspaceRoles } from '../../../../../shared/data/constants';
import WorkspacesSelect from '../../../../users/content/users/filters/WorkspacesSelect';
import { AuthContext } from '../../../../../auth/store/auth-context';
import { UserForm } from './UserDetailsDialog';

type CreateUserOptionsProps = {
  formData: UserForm,
  setFormData: React.Dispatch<React.SetStateAction<UserForm>>,
};

function CreateUserOptions({ formData, setFormData }: CreateUserOptionsProps) {
  const { state } = React.useContext(AuthContext);
  const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);

  React.useEffect(
    () => {
      setIsPlatformAdmin(state.user !== undefined && state.user.platformRole === PlatformRole.ADMIN);
    },
    [state]
  );

  if (!state.workspaceId) {
    return <div />;
  }

  return (
    <>
      <ListItem>
        {!isPlatformAdmin &&
          <AppSelect
            name="workspace"
            options={state.adminWorkspaces || []}
            value={formData.workspace || null}
            onChange={(value) => setFormData({ ...formData, workspace: value })}
          />
        }
        {isPlatformAdmin &&
          <WorkspacesSelect
            value={formData.workspace || null}
            onChange={(value) => setFormData({ ...formData, workspace: value })}
          />
        }
      </ListItem>
      {isPlatformAdmin &&
        <ListItem>
          <AppSelect
            name="workspaceRole"
            options={workspaceRoles}
            value={formData.workspaceRole || null}
            onChange={(value) => setFormData({ ...formData, workspaceRole: value })}
          />
        </ListItem>
      }
      <ListItem>
        <AppInput
          name="email"
          label="Email Address"
          value={formData.email || ''}
          onChange={(value) => setFormData({ ...formData, email: value })}
        />
      </ListItem>
    </>
  );
}

export default CreateUserOptions;
