import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 'auto',
    padding: '5px 14px 0px 14px',
    border: theme.app.border,
    borderRadius: 4,
    borderColor: '#c4c4c4',
    '&:hover': {
      borderColor: 'black',
    },
  },
  label: {
    background: 'white',
    padding: '0px 8px',
    marginLeft: -4,
  },
}));

type AppInputWrapperProps = {
  label: string,
  required?: boolean,
  children: React.ReactNode,
  contentClassName?: string,
};

function AppInputWrapper({ label, required, children, contentClassName }: AppInputWrapperProps) {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
    >
      <InputLabel
        shrink
        variant="outlined"
        className={classes.label}>
        {label}{required ? '*' : ''}
      </InputLabel>
      <div className={clsx(classes.content, contentClassName)}>
        {children}
      </div>
    </FormControl>
  );
}

export default AppInputWrapper;
