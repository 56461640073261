import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import AppPlacesAutocomplete, { AppAddress } from './PlacesAutocomplete';
import AppInput from './Input';

type AppAddressInputProps = {
  address: AppAddress,
  onChange: (location: AppAddress) => void,
};

function AppAddressInput({ address, onChange }: AppAddressInputProps) {
  return (
    <>
      <ListItem>
        <AppPlacesAutocomplete
          address={address.rawAddress || ''}
          onChange={value => onChange(value)}
        />
      </ListItem>
      <Divider />
      <ListItem style={{ marginTop: 8 }}>
        <AppInput
          name="address1"
          label="Address Line 1"
          value={address.address || ''}
          onChange={(value) => onChange({ ...address, address: value })}
          disabled
        />
      </ListItem>
      <ListItem>
        <AppInput
          name="address2"
          label="Address Line 2"
          value={address.address2 || ''}
          onChange={(value) => onChange({ ...address, address2: value })}
        />
      </ListItem>
      <ListItem>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppInput
              name="city"
              label="City"
              value={address.city || ''}
              onChange={(value) => onChange({ ...address, city: value })}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <AppInput
              name="state"
              label="State/Region"
              value={address.state || ''}
              onChange={(value) => onChange({ ...address, state: value })}
              disabled
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppInput
              name="postalCode"
              label="Postal Code"
              value={address.postalCode || ''}
              onChange={(value) => onChange({ ...address, postalCode: value })}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <AppInput
              name="country"
              label="Country"
              value={address.country || ''}
              onChange={(value) => onChange({ ...address, country: value })}
              disabled
            />
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}

export default AppAddressInput;
