import React from 'react';
import Link from '@material-ui/core/Link';
import { joinStrings, joinStringsWithSpace } from '../../utils/strings';
import AppCardTitle from '../typography/CardTitle';
import { AppAddress } from './PlacesAutocomplete';

function AppAddressDisplay({ rawAddress, name, address, address2, city, state, country, postalCode }: AppAddress) {
  return (
    <Link href={`https://www.google.com/maps/place/${joinStringsWithSpace(address, city, state, postalCode, country)}`} target="_blank">
      {address && <AppCardTitle text={address} />}
      {address2 && <AppCardTitle text={address2} />}
      <AppCardTitle text={joinStringsWithSpace(joinStrings(', ', city, state), postalCode)} />
    </Link>
  );
};

export default AppAddressDisplay;
