import React from 'react';
import { AppPhoneNumberProps } from '../../../../../../shared/display/phone/PhoneNumber';
import { analyticsTrackMutation } from '../../../../../../shared/utils/googleAnalytics';
import { trimAndNullEmpty } from '../../../../../../shared/utils/strings';
import { AddressInput } from '../../../../../../__generated__/globalTypes';
import UserDetailsDialog, { UserForm } from '../../../../content/users/create/UserDetailsDialog';
import { updateUserAddress } from '../../Profile';
import { UserProfileData } from '../../__generated__/UserProfileData';
import useEditUserMutation from './updateUserMutation';
import { UpdateUserMutationVariables } from './__generated__/UpdateUserMutation';

function getFormData(user: UserProfileData): UserForm {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    position: user.position || '',
    company: user.company || '',
    region: user.region || '',
    mobilePhone: user.mobilePhoneNumber,
    officePhone: user.officePhoneNumber,
  };
};

type EditUserProps = {
  user: UserProfileData,
  onClose: () => void,
  onUpdated: () => void,
};

function EditUser({ user, onClose, onUpdated }: EditUserProps) {
  const { updateUserMutation, loading, error } = useEditUserMutation(onUpdated);
  const [currentFormData, setCurrentFormData] = React.useState(getFormData(user));
  const [changedFormData, setChangedFormData] = React.useState(getFormData(user));

  React.useEffect(() => {
    updateUserAddress(user, (address) => setCurrentFormData({ ...getFormData(user), location: address }));
  }, [user]);

  React.useEffect(() => {
    setChangedFormData(currentFormData);
  }, [currentFormData]);

  const handleOnConfirm = (formData: UserForm) => {
    setChangedFormData(formData);
    const variables: UpdateUserMutationVariables = { userId: user.id };
    let hasChanges = false;
    if (trimAndNullEmpty(formData.firstName) !== user.firstName) {
      hasChanges = true;
      variables.firstName = trimAndNullEmpty(formData.firstName);
    }
    if (trimAndNullEmpty(formData.lastName) !== user.lastName) {
      hasChanges = true;
      variables.lastName = trimAndNullEmpty(formData.lastName);
    }
    if (trimAndNullEmpty(formData.position) !== user.position) {
      hasChanges = true;
      variables.position = trimAndNullEmpty(formData.position);
    }
    if (trimAndNullEmpty(formData.company) !== user.company) {
      hasChanges = true;
      variables.company = trimAndNullEmpty(formData.company);
    }
    if (trimAndNullEmpty(formData.region) !== user.region) {
      hasChanges = true;
      variables.region = trimAndNullEmpty(formData.region);
    }
    const mobilePhone: AppPhoneNumberProps = {
      countryCode: user.mobilePhoneNumber ? user.mobilePhoneNumber.countryCode : '',
      phoneNumber: user.mobilePhoneNumber ? user.mobilePhoneNumber.phoneNumber : '',
      extension: user.mobilePhoneNumber ? user.mobilePhoneNumber.extension : null
    };
    let hasMobilePhoneChanges = false;
    if (formData.mobilePhone?.phoneNumber !== user.mobilePhoneNumber?.phoneNumber) {
      hasChanges = true;
      hasMobilePhoneChanges = true;
      mobilePhone.phoneNumber = formData.mobilePhone != null ? formData.mobilePhone.phoneNumber : '';
    }
    if (formData.mobilePhone?.countryCode !== user.mobilePhoneNumber?.countryCode) {
      hasChanges = true;
      hasMobilePhoneChanges = true;
      mobilePhone.countryCode = formData.mobilePhone != null ? formData.mobilePhone.countryCode : '';
    }
    if (hasMobilePhoneChanges) {
      variables.mobilePhone = mobilePhone.countryCode !== '' && mobilePhone.phoneNumber !== '' ? mobilePhone : null;
    }
    const officePhone: AppPhoneNumberProps = {
      countryCode: user.officePhoneNumber ? user.officePhoneNumber.countryCode : '',
      phoneNumber: user.officePhoneNumber ? user.officePhoneNumber.phoneNumber : '',
      extension: user.officePhoneNumber ? user.officePhoneNumber.extension : null
    };
    let hasOfficePhoneChanges = false;
    if (formData.officePhone?.phoneNumber !== user.officePhoneNumber?.phoneNumber) {
      hasChanges = true;
      hasOfficePhoneChanges = true;
      officePhone.phoneNumber = formData.officePhone != null ? formData.officePhone.phoneNumber : '';
    }
    if (formData.officePhone?.countryCode !== user.officePhoneNumber?.countryCode) {
      hasChanges = true;
      hasOfficePhoneChanges = true;
      officePhone.countryCode = formData.officePhone != null ? formData.officePhone.countryCode : '';
    }
    if (formData.officePhone?.extension !== user.officePhoneNumber?.extension) {
      hasChanges = true;
      hasOfficePhoneChanges = true;
      officePhone.extension = formData.officePhone != null && formData.officePhone.extension !== '' ? formData.officePhone.extension : null;
    }
    if (hasOfficePhoneChanges) {
      variables.officePhone = officePhone.countryCode !== '' && officePhone.phoneNumber !== '' ? officePhone : null;
    }
    if (formData.location?.rawAddress !== user.workAddress || (user.address && trimAndNullEmpty(formData.location?.address2) !== user.address.address2)) {
      hasChanges = true;
      variables.address = formData.location && formData.location.rawAddress !== '' ? {
        address: formData.location.address?.trim(),
        address2: trimAndNullEmpty(formData.location.address2),
        city: formData.location.city?.trim() || '',
        state: formData.location.state?.trim() || '',
        country: formData.location.countryCode?.trim() || '',
        postalCode: formData.location.postalCode?.trim() || '',
        coordinates: formData.location.coordinates,
      } as AddressInput : null;
    }
    if (hasChanges) {
      analyticsTrackMutation('update_user', Object.keys(variables).join(', '));
      updateUserMutation(variables);
    } else {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onClose();
    setChangedFormData(currentFormData);
  };

  return (
    <UserDetailsDialog
      title="Edit User"
      user={changedFormData}
      open={true}
      loading={loading}
      error={error}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
    />
  );
}

export default EditUser;
