type LocalStorageType = 'string' | 'number' | 'array' | 'object' | 'boolean'

function getType(value: any): string | null {
  var match = ({}).toString.call(value).match(/\s([a-zA-Z]+)/);
  if (!match) {
    return null;
  }
  return match[1].toLowerCase();
}

export function getLocalStorageData<T>(key: string, type: LocalStorageType): T {
  let value: any;
  try {
    value = JSON.parse(localStorage[key]);
  } catch (e) {
    value = undefined;
  }
  return (value === null || getType(value) === type) ? value : undefined;
}

export function setLocalStorageData(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorageData<T>(key: string, type: LocalStorageType): T {
  let value: any;
  try {
    value = JSON.parse(sessionStorage[key]);
  } catch (e) {
    value = undefined;
  }
  return (value === null || getType(value) === type) ? value : undefined;
}

export function setSessionStorageData(key: string, value: any): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}
