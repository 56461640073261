import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AppParagraphTitle from '../typography/ParagraphTitle';

type AppConfirmDialogProps = {
  text: string,
  open: boolean,
  onConfirm: (confirm: boolean) => void,
}

function AppConfirmDialog({ text, open, onConfirm }: AppConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle>
        Please Confirm
      </DialogTitle>
      <DialogContent dividers>
        <AppParagraphTitle text={text} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(true)}>
          Yes
        </Button>
        <Button onClick={() => onConfirm(false)}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AppConfirmDialog;
