import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AppParagraphProps } from './Paragraph';

function AppCardTitle({ text, className, align }: AppParagraphProps) {
  return (
    <Typography
      style={{ fontWeight: 500 }}
      variant="subtitle1"
      className={className}
      align={align}
    >
      {text}
    </Typography>
  );
}

export default AppCardTitle;
