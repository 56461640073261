import React from 'react';
import { ApolloError } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { LocationInput } from '../../../../../__generated__/globalTypes';
import AppDialog from '../../../../../shared/display/dialog/Dialog';
import { NotificationType } from '../../../../../shared/display/form/notifications';
import AppPlacesAutocomplete, { AppAddress } from '../../../../../shared/display/form/PlacesAutocomplete';
import AppInput from '../../../../../shared/display/form/Input';
import AppPhoneNumberInput from '../../../../../shared/display/phone/PhoneNumberInput';
import { AppPhoneNumberProps } from '../../../../../shared/display/phone/PhoneNumber';
import { AppValue } from '../../../../../shared/data/types';
import CreateUserOptions from './CreateUserOptions';
import AppAddressInput from '../../../../../shared/display/form/AddressInput';
import ProfileWorkAddressInput from '../../../../account/settings/personalInfo/cards/contactInfo/workAddress/ProfileWorkAddressInput';

export type UserForm = {
  firstName: string,
  lastName: string,
  position: string,
  company: string,
  region: string,
  mobilePhone: AppPhoneNumberProps | null,
  officePhone: AppPhoneNumberProps | null,
  location?: AppAddress,
  workspace?: AppValue | null,
  workspaceRole?: AppValue | null,
  email?: string,
}

function isFormValid(user: UserForm, isCreate?: boolean): boolean {
  return user.firstName !== '' && user.lastName !== '' && (!isCreate || (user.workspace !== undefined && user.workspace != null && user.email !== '' && user.email !== undefined));
}

type UserDetailsDialogProps = {
  title: string,
  user: UserForm,
  open: boolean,
  loading: boolean,
  error?: ApolloError,
  isCreate?: boolean,
  onClose: () => void,
  onConfirm: (data: UserForm) => void,
};

function UserDetailsDialog({ title, user, open, loading, error, isCreate, onClose, onConfirm }: UserDetailsDialogProps) {
  const [formData, setFormData] = React.useState<UserForm>(user);
  const [notificationType, setNotificationType] = React.useState<NotificationType>('None');

  React.useEffect(
    () => {
      setNotificationType(loading ? 'Loader' : error ? 'Error' : 'None');
    },
    [loading, error]
  );

  React.useEffect(
    () => {
      setFormData(user);
    },
    [user]
  );

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      onConfirm={() => onConfirm(formData)}
      onGoBack={() => setNotificationType('None')}
      title={title}
      confirmEnabled={!loading && isFormValid(formData, isCreate)}
      notificationType={notificationType}
      notification={error?.message}
      disableContentPadding
      minWidth={300}
    >
      <List>
        {isCreate && (
          <CreateUserOptions
            formData={formData}
            setFormData={setFormData}
          />
        )}
        <ListItem>
          <AppInput
            name="firstName"
            label="First Name"
            value={formData.firstName}
            onChange={(value) => setFormData({ ...formData, firstName: value })}
          />
        </ListItem>
        <ListItem>
          <AppInput
            name="lastName"
            label="Last Name"
            value={formData.lastName}
            onChange={(value) => setFormData({ ...formData, lastName: value })}
          />
        </ListItem>
        <ListItem>
          <AppInput
            name="position"
            label="Position"
            value={formData.position}
            onChange={(value) => setFormData({ ...formData, position: value })}
          />
        </ListItem>
        <ListItem>
          <AppInput
            name="company"
            label="Company"
            value={formData.company}
            onChange={(value) => setFormData({ ...formData, company: value })}
          />
        </ListItem>
        <ListItem>
          <AppInput
            name="region"
            label="Region"
            value={formData.region}
            onChange={(value) => setFormData({ ...formData, region: value })}
          />
        </ListItem>
        <ListItem>
          <AppPhoneNumberInput
            name="Mobile Phone"
            value={formData.mobilePhone}
            onChange={(value) => setFormData({ ...formData, mobilePhone: value })}
          />
        </ListItem>
        <ListItem>
          <AppPhoneNumberInput
            name="Office Phone"
            value={formData.officePhone}
            onChange={(value) => setFormData({ ...formData, officePhone: value })}
            hasExtension
          />
        </ListItem>
        <ProfileWorkAddressInput
          address={formData.location || { rawAddress: '' }}
          onChange={(value) => setFormData({ ...formData, location: value })}
        />
      </List>
    </AppDialog>
  );
}

export default UserDetailsDialog;
