import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    app: {
      toolbarHeight: React.CSSProperties['height'],
      headerHeight: React.CSSProperties['height'],
      infoDrawerWidth: React.CSSProperties['width'],
      border: React.CSSProperties['border'],
      errorColor: React.CSSProperties['color'],
      backgroundColor: React.CSSProperties['color'],
    }
  }
  interface ThemeOptions {
    app?: {
      toolbarHeight?: React.CSSProperties['height'],
      headerHeight?: React.CSSProperties['height'],
      infoDrawerWidth?: React.CSSProperties['width'],
      border?: React.CSSProperties['border'],
      errorColor?: React.CSSProperties['color'],
      backgroundColor?: React.CSSProperties['color'],
    }
  }
}

const primaryColor = '#00193b';
const backgroundColor = '#f4f6f8';
const headerHeight = 40;
const border = `1px solid ${grey[300]}`;

function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    app: {
      toolbarHeight: 48,
      headerHeight: headerHeight,
      border: border,
      infoDrawerWidth: 300,
      errorColor: red[500],
      backgroundColor: backgroundColor,
    },
    ...options,
  })
}

const theme = createMyTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: 10,
            height: 10,
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: backgroundColor,
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#d6dee1',
            borderRadius: 10,
            border: '1px solid transparent',
            backgroundClip: 'content-box',
          },
          'textarea': {
            fontSize: '1rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            padding: '4px 6px',
            borderColor: grey[300],
            outline: 'none',
            resize: 'none',
            width: '100%',
            '&::placeholder': {
              opacity: 0.5,
            },
          },
          'body': {
            backgroundColor: backgroundColor,
            minWidth: 300,
          },
          '.MuiPaper-rounded': {
            borderRadius: '0 !important',
          },
          '.image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide': {
            height: '100% !important',
            background: 'black',
          },
          '.image-gallery-index': {
            top: 'auto !important',
            bottom: 0,
            margin: 0,
            background: 'none !important',
            fontSize: 11,
            padding: '10px !important',
          },
          '.image-gallery-svg': {
            width: '36px !important',
            height: '36px !important',
          },
          '.image-gallery-icon': {
            height: '50px !important',
            padding: '0 !important',
            color: 'white !important',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.15)',
            },
          },
          '.MuiPickersDay-dayDisabled': {
            color: 'rgba(0, 0, 0, 0.3) !important',
          },
          'p': {
            wordBreak: 'break-word',
          },
          '.MuiMenu-list': {
            padding: '0 !important',
          },
          '.app-mobile': {
            '& .app-mobile-spacing': {
              background: 'white',
              '& .MuiPaper-rounded': {
                borderRadius: '0',
                borderBottom: border,
              },
              '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
                paddingTop: 0,
                paddingBottom: 0,
              },
              '& .MuiGrid-spacing-xs-2': {
                marginTop: 0,
                marginBottom: 0,
              },
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: '16px !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: border,
          maxHeight: headerHeight,
          '& .MuiTypography-h5': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          overflow: 'auto',
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: border,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        entered: {
          height: `calc(100% - ${headerHeight}px) !important`,
          overflow: 'auto',
        },
        wrapper: {
          height: '100%',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
          overflow: 'hidden',
        },
        toolbar: {
          minHeight: headerHeight,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '10px 16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          padding: '10px 16px',
        }
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiTypography-body1': {
            fontSize: '0.875rem',
            fontWeight: 500,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          lineHeight: '1.4rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          marginRight: -8,
        },
        hasPopupIcon: {
          '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: '50px !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 48,
            borderBottom: border,
          },
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
  },
});

export default theme;
