import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutProvider from './store/layout-store';
import Navigation from './navigation/Navigation';
import AppToolbar from './toolbar/Toolbar';
import UserProfile from '../auth/UserProfile';

const useStyles = makeStyles((theme) => ({
  denseToolbar: {
    minHeight: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
    width: '100%',
    height: '100vh',
  },
}));

type LayoutProps = {
  children: React.ReactNode,
};

function Layout({ children }: LayoutProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <UserProfile>
      <LayoutProvider>
        <AppToolbar onDrawerToggle={handleDrawerToggle} />
        <Navigation
          onDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <main className={classes.content}>
          <div className={classes.denseToolbar} />
          {children}
        </main>
      </LayoutProvider>
    </UserProfile>
  );
}

export default Layout;
