import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { apolloLink } from '../../../shared/data/apolloClient';

const groupDetailsApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(),
        },
      },
      Group: {
        fields: {
          alerts: relayStylePagination(),
          memberships: relayStylePagination(),
        },
      },
    },
  }),
});

export default groupDetailsApolloClient;
