import { Route, RouteState } from './route-store';

export type RouteAction = {
  type: 'CHANGE_ROUTE',
  payload: Route,
};

const routeReducer = (state: RouteState, action: RouteAction) => {
  switch (action.type) {
    case 'CHANGE_ROUTE':
      return {
        ...state,
        route: action.payload,
      };
    default:
      return state;
  }
};

export default routeReducer;
