import React from 'react';
import { gql } from '@apollo/client';
import CardMedia from '@material-ui/core/CardMedia';
import { UploadMedia } from './__generated__/UploadMedia';

AppTreuthUpload.fragments = {
  details: gql`
    fragment UploadDetails on TreuthUpload {
      id,
      createdDate,
      url,
      contentType,
      handle,
    }
    `,
  media: gql`
    fragment UploadMedia on TreuthUpload {
      id,
      createdDate,
      url,
      contentType,
    }
    `,
};

const validVideoContentTypes = [
  'video/mp4',
];

export const isMediaImage = (contentType: string) => contentType.startsWith('image');
export const isMediaVideo = (contentType: string) => validVideoContentTypes.indexOf(contentType) !== -1;
export const findFirstImage = (media: UploadMedia[]) => media.find(item => isMediaImage(item.contentType))?.url;

type TreuthUploadProps = {
  url: string,
  contentType: string,
  className: string,
  onClick?: () => void,
}

function AppTreuthUpload({ url, contentType, className, onClick }: TreuthUploadProps) {
  if (isMediaImage(contentType)) {
    return <CardMedia
      image={url}
      className={className}
      onClick={onClick}
    />;
  }
  if (isMediaVideo(contentType)) {
    return (
      <video
        className={className}
        controls
        onClick={onClick}
      >
        <source src={url} type={contentType} />
      </video>
    );
  }
  return <></>;
}

export default AppTreuthUpload;
