import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { AuthContext } from '../auth/store/auth-context';

interface AppRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  layout: React.ComponentType<any>;
};

function AppRoute({ component: Component, layout: Layout, ...rest }: AppRouteProps) {
  const { state } = React.useContext(AuthContext);

  return (
    <Route {...rest} render={(props) =>
      state.accessToken === '' ? (
        <Redirect to="/login" />
      ) : (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        )
    } />
  );
}

export default AppRoute;
