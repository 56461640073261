export const appLocale = 'en-US';//(navigator.languages && navigator.languages[0]) || navigator.language;
export const appDateTimeFormat = appLocale === 'en-US' ? 'MMM d, yyyy hh:mm a' : 'd MMM yyyy HH:mm';
export const appDateFormat = appLocale === 'en-US' ? 'MMM d, yyyy' : 'd MMM yyyy';

export type Currency = {
    value: number,
    currency: string,
};

export function formatCurrency(value: number, currency: string, maximumFractionDigits: number = 0) {
    try {
        return new Intl.NumberFormat(appLocale, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: maximumFractionDigits,
        }).format(value);
    } catch {
        return formatNumber(value);
    }
};

export function formatNumber(value: number, maximumFractionDigits: number = 2) {
    return new Intl.NumberFormat(appLocale, { maximumFractionDigits: maximumFractionDigits }).format(value);
};
