import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { formatCurrency } from '../../../../shared/utils/internationalization';
import AppDescriptionBox from '../../../../shared/display/graphic/DescriptionBox';
import AppParagraphTitle from '../../../../shared/display/typography/ParagraphTitle';
import { joinStringsWithSpace } from '../../../../shared/utils/strings';
import { AlertDetailsQuery_alert } from '../../details/__generated__/AlertDetailsQuery';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontSize: '0.8rem',
    marginTop: -6,
    marginBottom: -3,
    color: 'rgba(0, 0, 0, 0.54)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  assignee: {
    maxWidth: 300,
  },
}));

AlertOverview.fragments = {
  estimatedValue: gql`
    fragment EstimatedValueDetails on EstimatedValue {
      amount,
      currencyCode,
    },
    `,
}

export function getCaseValue(alert: AlertDetailsQuery_alert) {
  return alert.estimatedValue?.amount && alert.estimatedValue?.currencyCode ? formatCurrency(alert.estimatedValue.amount, alert.estimatedValue.currencyCode) : 'NA';
}

type AlertOverviewProps = {
  alert: AlertDetailsQuery_alert,
}

function AlertOverview({ alert }: AlertOverviewProps) {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppDescriptionBox
        title="Case Value"
        value={getCaseValue(alert)}
      />
      <AppDescriptionBox title="Assignee" noBorder className={classes.assignee}>
        <AppParagraphTitle text={joinStringsWithSpace(alert.assignee.firstName, alert.assignee.lastName)} />
        {alert.assignee.company && (
          <AppParagraphTitle className={classes.subtitle} text={alert.assignee.company} />
        )}
      </AppDescriptionBox>
    </Box>
  );
}

export default AlertOverview;
