import { LayoutState, ToolbarTitle } from './layout-store';
import { setLocalStorageData } from '../../shared/utils/localStorage';
import { CONTENT_FILTERS_OPEN } from '../../shared/data/constants';

export type LayoutAction = {
  type: 'HAS_FILTERS' | 'TOGGLE_FILTERS',
  payload: boolean,
} | {
  type: 'CHANGE_TITLE',
  payload: ToolbarTitle,
};

const layoutReducer = (state: LayoutState, action: LayoutAction) => {
  switch (action.type) {
    case 'TOGGLE_FILTERS':
      setLocalStorageData(CONTENT_FILTERS_OPEN, action.payload);
      return {
        ...state,
        filtersOpen: action.payload,
      };
    case 'HAS_FILTERS':
      return {
        ...state,
        hasFilters: action.payload,
      };
    case 'CHANGE_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};

export default layoutReducer;
