import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AppParagraphProps } from './Paragraph';

function AppParagraphTitle({ text, className, align }: AppParagraphProps) {
  return (
    <Typography
      variant="subtitle2"
      className={className}
      align={align}
    >
      {text}
    </Typography>
  );
}

export default AppParagraphTitle;
