import { gql, useMutation } from '@apollo/client';
import apolloClient, { usersApolloClient } from '../../../../../../shared/data/apolloClient';
import alertsContentApolloClient from '../../../../../alerts/content/alertsContentApolloClient';
import alertDetailsApolloClient from '../../../../../alerts/details/alertDetailsApolloClient';
import groupDetailsApolloClient from '../../../../../groups/details/groupDetailsApolloClient';
import { UpdateUserMutation, UpdateUserMutationVariables } from './__generated__/UpdateUserMutation';
import alertDetailsPrintApolloClient from '../../../../../alertPrint/alertDetailsPrintApolloClient';
import dashboardMapApolloClient from '../../../../../dashboard/recentAlertsLookback/dashboardMapApolloClient';
import alertsMapApolloClient from '../../../../../alerts/content/map/alertsMapApolloClient';

const UPDATE_USER_MUTATION = gql`
mutation UpdateUserMutation(
  $userId: Int!,
  $firstName: String,
  $lastName: String,
  $position: String,
  $company: String,
  $region: String,
  $mobilePhone: PhoneNumberInput,
  $officePhone: PhoneNumberInput,
  $address: AddressInput,
) {
  updateUser(
    input: {
      id: $userId,
      updatedFields: {
        firstName: $firstName,
        lastName: $lastName,
        position: $position,
        company: $company,
        region: $region,
        mobilePhone: $mobilePhone,
        officePhone: $officePhone,
        address: $address,
      }
    }
  ) {
    id,
  },
},
`;

function useUpdateUserMutation(onCompleted: () => void) {
  const [updateUser, { loading, error }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UPDATE_USER_MUTATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        Promise.all([
          alertsContentApolloClient.clearStore(),
          dashboardMapApolloClient.clearStore(),
          alertsMapApolloClient.clearStore(),
          alertDetailsApolloClient.clearStore(),
          alertDetailsPrintApolloClient.clearStore(),
          apolloClient.clearStore(),
          groupDetailsApolloClient.clearStore(),
          usersApolloClient.clearStore(),
        ])
          .then(() => {
            onCompleted();
          });
      },
      onError: () => { },
    }
  );

  const updateUserMutation = (variables: UpdateUserMutationVariables) => {
    updateUser({
      variables: variables,
    });
  };

  return {
    updateUserMutation,
    loading,
    error,
  };
}

export default useUpdateUserMutation;
