import React from 'react';
import { gql, } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import clsx from 'clsx';
import { ApolloProvider } from '@apollo/client';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import apolloClient from './shared/data/apolloClient';
import { appLocale } from './shared/utils/internationalization';
import theme from './theme';
import { enUS, enGB } from 'date-fns/locale';
import AppRoutes from './routes/AppRoutes';
import { AuthProvider } from './auth/store/auth-context';
import AlertOverview from './pages/alerts/shared/description/AlertOverview';
import useSuspectsQuery from './pages/workspaces/details/cards/suspectsQuery';


App.fragments = {
  details: gql`
    fragment SuspectCardDetails on Suspect {
      ...SuspectDetails,
      alerts {
        id,
        estimatedValue {
          ...EstimatedValueDetails,
        },
        subcategories {
          id,
          name,
        },
      },
    }
  ${useSuspectsQuery.fragments.details},
  ${AlertOverview.fragments.estimatedValue},
  `,
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

function App() {
  const classes = useStyles();
  const dateLocale = appLocale === 'en-US' ? enUS : enGB;
  const globalTheme = useTheme();
  const isMobile = useMediaQuery(globalTheme.breakpoints.down('sm'));

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLocale}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <div className={clsx(classes.root, {
              'app-mobile': isMobile,
            })}>
              <CssBaseline />
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </div>
          </AuthProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
