import React from 'react';
import { AppValue } from '../../../../../shared/data/types';
import AppSelectLoader, { SelectProps } from '../../../../../shared/display/select/SelectLoader';
import useWorkspacesQuery from './workspacesQuery';

type WorkspacesSelectProps = {
  filter?: number[],
} & SelectProps;

function WorkspacesSelect({ onChange, value, filter }: WorkspacesSelectProps) {
  const { workspaces, fetching, error } = useWorkspacesQuery();
  const options: AppValue[] = workspaces.filter(item => filter === undefined || filter.indexOf(item.id) === -1)
    .map(item => {
      return {
        id: item.id,
        name: item.title
      };
    });

  return (
    <AppSelectLoader
      name="workspaces"
      loading={fetching}
      error={error}
      options={options}
      value={value}
      onChange={newValue => onChange(newValue)}
    />
  );
}

export default WorkspacesSelect;
