import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { apolloLink } from '../../../shared/data/apolloClient';

const alertDetailsApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(),
        },
      },
      Alert: {
        fields: {
          attachments: relayStylePagination(),
          comments: relayStylePagination(),
          followers: relayStylePagination(),
          groups: relayStylePagination(),
        },
      },
      Organization: {
        fields: {
          memberships: relayStylePagination(),
          alerts: relayStylePagination(),
          suspects: relayStylePagination(),
          vehicles: relayStylePagination(),
          groups: relayStylePagination(),
        },
      },
    },
  }),
});

export default alertDetailsApolloClient;
