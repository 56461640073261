import React from 'react';
import startCase from 'lodash/startCase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { AppValue } from '../../data/types';

export const convertToOptions = (data: string[]): AppValue[] => data.map((item, index) => {
  return {
    id: index,
    name: item,
  };
});

export type AppSelectSharedPropsParent<T> = {
  name: string,
  options: T[],
  value?: T | null,
  required?: boolean,
  placeholder?: string,
  clearable?: boolean,
  allowCustomValues?: boolean,
  onInputChange?: (value: string) => void,
};

export type AppSelectProps<T> = {
  onChange: (value: T | null) => void,
} & AppSelectSharedPropsParent<T>;

type AppSelectSharedProps<T> = {
  onAutocompleteChange: (value: T | null | NonNullable<string | T>) => void,
  getOptionLabel: (option: T) => string,
  getOptionSelected: (option: T, value: T) => boolean,
} & AppSelectSharedPropsParent<T>;

export function AppSelectShared<T>({ name, options, value, required, placeholder = 'Empty', onAutocompleteChange, onInputChange, clearable, getOptionLabel, getOptionSelected, allowCustomValues }: AppSelectSharedProps<T>) {
  return (
    <Autocomplete
      disableClearable={clearable !== true}
      fullWidth
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterSelectedOptions
      value={value}
      onChange={(e, newOptions) => {
        if (!allowCustomValues) {
          onAutocompleteChange(newOptions);
        }
      }}
      onInputChange={(e, value) => {
        if (allowCustomValues && onInputChange) {
          onInputChange(value)
        }
      }}
      size="small"
      freeSolo={allowCustomValues}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={startCase(name)}
          required={required}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: placeholder !== undefined ? true : undefined,
          }}
        />
      )}
    />
  );
}

function AppSelect({ name, options, value, required, placeholder, onChange, clearable }: AppSelectProps<AppValue>) {
  const handleChange = (value: AppValue | null | NonNullable<string | AppValue>) => {
    if (!value) {
      onChange(null);
    }
    if (typeof value === 'string') {
      value = { id: 0, name: value };
    }
    onChange(value);
  };

  return (
    <AppSelectShared
      name={name}
      options={options}
      value={value}
      required={required}
      placeholder={placeholder}
      onAutocompleteChange={handleChange}
      clearable={clearable}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => value.id === option.id}
    />
  );
}

export default AppSelect;
