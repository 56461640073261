import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setAccessToken, getRefreshToken, setRefreshToken } from '../../auth/store/auth-context';
import { analyticsTrackException } from '../utils/googleAnalytics';

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

function goToLogin() {
  pendingRequests = [];
  setAccessToken('');
  window.location.href = '/login';
};

const apolloErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      if (error.message === 'Unauthorized' || error.message === 'User does not belong to the queried organization') {
        let forward$;
        const oldHeaders = operation.getContext().headers;
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            fetch(`${process.env.REACT_APP_SCHEMA}`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                query: `query {
                    refreshToken(refreshToken: "${getRefreshToken()}") {
                        accessToken
                        refreshToken {
                          token
                        }
                      }
                  }`,
              }),
            })
              .then(response => response.json())
              .then((data) => {
                if ((data.errors && data.errors.length > 0) || !data || !data.data || !data.data.refreshToken) {
                  goToLogin();
                  return;
                }
                setAccessToken(data.data.refreshToken.accessToken);
                setRefreshToken(data.data.refreshToken.refreshToken.token);
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: data.data.refreshToken.accessToken,
                  },
                });
                resolvePendingRequests();
                return data.data.refreshToken.accessToken;
              })
              .catch(error => {
                goToLogin();
                return;
              })
              .finally(() => {
                isRefreshing = false;
              })
          ).filter((value: any) => Boolean(value));
        } else {
          forward$ = fromPromise(
            new Promise(resolve => {
              pendingRequests.push(resolve);
            })
          );
        }
        return forward$.flatMap(() => forward(operation));
      } else {
        analyticsTrackException(JSON.stringify(graphQLErrors.map(error => error.message)));
      }
    }
  }
});

export default apolloErrorLink;
