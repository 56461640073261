import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { apolloLink } from '../../../shared/data/apolloClient';

const vehiclesContentApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Vehicle: {
        fields: {
          attachments: relayStylePagination(),
        },
      },
      Organization: {
        fields: {
          vehicles: relayStylePagination(),
        },
      },
    },
  }),
});

export default vehiclesContentApolloClient;
