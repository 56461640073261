import { ApolloClient, InMemoryCache, ApolloError, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { getAccessToken } from '../../auth/store/auth-context';
import apolloErrorLink from './apolloErrorLink';

export type ApolloQueryProps<T> = {
  loading?: boolean,
  data: T | undefined,
  error?: ApolloError,
};

export type ApolloPaginatedQueryProps<T> = {
  count: number,
  loadMore: () => void,
  hasNextPage: boolean,
} & ApolloQueryProps<T>;

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_SCHEMA}`,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

export const apolloLink = ApolloLink.from([apolloErrorLink, authLink, httpLink]);

export const dashboardApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Organization: {
        fields: {
          alerts: relayStylePagination(),
        },
      },
      User: {
        fields: {
          authoredAlerts: relayStylePagination(),
          assignedAlerts: relayStylePagination(),
          followedAlerts: relayStylePagination(),
          groupMemberships: relayStylePagination(),
        },
      },
    },
  }),
});

export const usersApolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Organization: {
        fields: {
          memberships: relayStylePagination(),
        },
      },
      User: {
        fields: {
          authoredAlerts: relayStylePagination(),
          assignedAlerts: relayStylePagination(),
          followedAlerts: relayStylePagination(),
        },
      },
    },
  }),
});

const apolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({}),
});

export default apolloClient;