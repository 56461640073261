import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationType } from '../form/notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppError from '../typography/Error';
import AppParagraphTitle from '../typography/ParagraphTitle';
import AppConfirmDialog from './ConfirmDialog';

export type AppDialogParentProps = {
  open: boolean,
  onClose: () => void,
}

type AppDialogProps = {
  title: string,
  children: React.ReactNode,
  confirmEnabled?: boolean,
  notificationType: NotificationType,
  notification?: string,
  open: boolean,
  onClose: () => void,
  onConfirm?: () => void,
  onGoBack?: () => void,
  rootClassName?: string,
  closeButtonText?: string,
  contentClassName?: string,
  footerActions?: React.ReactNode,
  disableContentPadding?: boolean,
  disableActions?: boolean,
  disableBackdropClick?: boolean,
  minWidth?: number,
  closeConfirmation?: boolean,
  headerActions?: React.ReactNode,
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    float: 'right',
    padding: 4,
  },
  noPadding: {
    padding: 0,
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      borderRadius: 0,
    },
  }
}));

function AppDialog({
  open,
  title,
  children,
  notificationType,
  notification,
  confirmEnabled,
  onClose,
  onConfirm,
  onGoBack,
  rootClassName,
  contentClassName,
  closeButtonText = 'Close',
  footerActions,
  disableContentPadding,
  disableActions,
  minWidth = 300,
  disableBackdropClick,
  closeConfirmation,
  headerActions,
}: AppDialogProps) {
  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleOnClose = () => {
    if (closeConfirmation) {
      setOpenConfirm(true);
    } else {
      onClose();
    }
  };

  let content = children;
  let actions = null;
  switch (notificationType) {
    case 'None':
      actions = (
        <>
          {footerActions}
          {onConfirm &&
            <Button
              onClick={onConfirm}
              disabled={confirmEnabled !== true}
              children="Save" />
          }
        </>
      );
      break;
    case 'Skip':
      actions = (
        <>
          {footerActions}
          <Button onClick={onClose} children="Skip" />
        </>
      );
      break;
    case 'Error':
      content = <AppParagraphTitle text={notification || 'Unable to perform action. Please try again later.'} />
      actions = (
        <Button
          onClick={onGoBack}>
          Go Back
        </Button>
      );
      break;
    case 'Loader':
      actions = <CircularProgress size={30} />
      break;
    default:
      actions = (
        <Button
          onClick={handleOnClose}>
          {closeButtonText}
        </Button>
      );
  }

  const handleDialogOnClose = (event: any, reason: string) => {
    if (!disableBackdropClick || reason !== 'backdropClick') {
      handleOnClose();
    }
  };

  const handleConfirmDialog = (confirm: boolean) => {
    setOpenConfirm(false);
    if (confirm) {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        onClose={handleDialogOnClose}
        open={open}
        classes={{ paper: clsx(rootClassName, classes.root) }}
        PaperProps={{ style: { minWidth: minWidth } }}
      >
        <DialogTitle>
          {title}
          {headerActions ? headerActions : (
            <IconButton
              onClick={handleOnClose}
              className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          dividers
          children={content}
          className={clsx(contentClassName, {
            [classes.noPadding]: disableContentPadding && notificationType !== 'Error',
          })}
        />
        {disableActions !== true &&
          <DialogActions children={actions} />
        }
      </Dialog>
      <AppConfirmDialog
        text="Are you sure you want to discard?"
        open={openConfirm}
        onConfirm={handleConfirmDialog}
      />
    </>
  );
}

export default AppDialog;
