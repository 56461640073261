import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as Logo } from '../../img/treuth-logo.svg';
import routes, { Route } from '../../routes/routes';
import NavigationRoute from '../NavigationRoute';
import { AuthContext, AuthState } from '../../auth/store/auth-context';

export const drawerWidth = 220;

const secondaryColor = 'white';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'inherit',
    },
  },
  list: {
    paddingTop: 0,
  },
  brand: {
    paddingTop: 5,
    paddingBottom: 0,
  },
  brandLogo: {
    width: 30,
    fill: secondaryColor,
  },
  brandTitle: {
    color: secondaryColor,
    marginLeft: 10,
  },
  listIcon: {
    color: 'inherit',
    minWidth: 40,
  },
}));

export function getIsAllowedToSeeRoute(route: Route | undefined, auth: AuthState) {
  const workspaceSettings = (auth.workspaces || []).find(item => item.id == auth.workspaceId);
  return (route?.onlyAllowForAutoTheft != true || auth.workspace?.autoTheft == true) &&
    (!route?.allowedRoles || route.allowedRoles.some(role => role == workspaceSettings?.role));
}

function getNavigationRoutes(routes: Route[], auth: AuthState) {
  return routes
    .filter(route => route.hide !== true && getIsAllowedToSeeRoute(route, auth))
    .map(route => <NavigationRoute key={route.title} route={route} />);
}

type NavigationProps = {
  mobileOpen: boolean,
  onDrawerToggle: React.EventHandler<any>,
  window?: Function,
};

function Navigation({ window, mobileOpen, onDrawerToggle }: NavigationProps) {
  const classes = useStyles();
  const { state: authState } = React.useContext(AuthContext);
  const navigationRoutes = useMemo(() => getNavigationRoutes(routes, authState), [authState]);
  const container = window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const permanentDrawer = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <List className={classes.list}>
      <ListItem
        button
        component={NavLink}
        to="/"
        className={classes.brand}
      >
        <ListItemIcon className={classes.listIcon}>
          <Logo className={classes.brandLogo} />
        </ListItemIcon>
        <ListItemText
          primary="TREUTH"
          primaryTypographyProps={{ variant: 'h5', className: classes.brandTitle }}
        />
      </ListItem>
      {navigationRoutes}
    </List>
  );
  return (
    <nav className={classes.drawer}>
      {permanentDrawer ? (
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
          children={drawer}
        />
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={onDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
          children={drawer}
        />
      )}
    </nav>
  );
}

export default Navigation;
