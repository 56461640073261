import { getWorkspaceName } from "../../auth/store/auth-context";

export type CategoryType = 'exception' | 'mutation' | 'profile' | 'alerts' | 'groups' | 'suspects' | 'users' | 'vehicles' | 'reports';

type ExceptionActionType = 'graphql_error';

export type MutationActionType = 'forgot_password' | 'reset_password' | 'update_user_company' |
    'update_user_name' | 'update_user_position' | 'update_user_region' |
    'update_user_mobile_phone' | 'update_user_office_phone' | 'update_user_work_address' |
    'update_password' | 'upload_user_photo' | 'create_alert' |
    'add_alert_attachments' | 'add_alert_comment' | 'update_alert_followers' | 'remove_alert_attachment' | 'change_primary_attachment' |
    'update_alert_groups' | 'update_alert_suspects' | 'remove_alert_suspects' | 'add_alert_suspects' | 'update_alert_vehicles' | 'assign_alert' |
    'remove_alert_vehicles' | 'add_alert_vehicles' |
    'update_alert' | 'update_alert_status' | 'create_group' |
    'join_group' | 'leave_group' | 'add_group_users' | 'remove_group_users' | 'disable_group' | 'enable_group' |
    'update_group_name' | 'update_group_photo' | 'create_suspect' |
    'add_suspect_attachments' | 'update_suspect_vehicles' | 'update_suspect' | 'remove_suspect_attachment' | 'change_suspect_primary_attachment' |
    'create_user' | 'update_user' | 'update_user_photo' |
    'create_vehicle' | 'add_vehicle_attachments' | 'update_vehicle_suspects' | 'remove_vehicle_attachment' | 'change_vehicle_primary_attachment' |
    'update_vehicle' | 'update_workspace_photo' | 'follow_alert' | 'unfollow_alert' |
    'remove_user_from_workspace' | 'add_user_to_workspace' | 'update_recovery_vehicle' | 'create_recovery_vehicle' |
    'create_arrest' | 'update_arrest' | 'delete_arrest' | 'delete_alert_links' | 'add_alert_links';

type ProfileActionType = 'login' | 'logout' | 'change_workspace' |
    'open_change_workspace_menu' | 'open_edit_profile' | 'close_edit_profile';

type AlertsActionType = 'open_edit_followers' | 'close_edit_followers' | 'open_edit_groups' | 'close_edit_groups' |
    'open_edit_suspects' | 'close_edit_suspects' | 'open_edit_vehicles' | 'close_edit_vehicles' |
    'open_add_suspects' | 'close_add_suspects' | 'open_remove_suspects' | 'close_remove_suspects' | 'open_create_suspect' | 'close_create_suspect' |
    'open_add_vehicles' | 'close_add_vehicles' | 'open_remove_vehicles' | 'close_remove_vehicles' | 'open_create_vehicle' | 'close_create_vehicle' |
    'open_assign' | 'close_assign' | 'open_set_status' | 'close_set_status' | 'open_print' |
    'open_add_alert_links' | 'open_remove_alert_links' | 'close_add_alert_links' | 'close_remove_alert_links';

type GroupsActionType = 'open_edit_name' | 'close_edit_name' | 'open_add_users' | 'close_add_users' | 'open_remove_users' | 'close_remove_users';

type SuspectsActionType = 'open_edit_suspect_vehicles' | 'close_edit_suspect_vehicles';

type VehiclesActionType = 'open_edit_vehicle_suspects' | 'close_edit_vehicle_suspects' | 'plate_search';

type UsersActionType = 'open_remove_from_workspace' | 'close_remove_from_workspace' | 'open_add_to_workspace' | 'close_add_to_workspace';

type SharedActionType = 'open_create' | 'close_create' | 'open_edit' | 'close_edit' | 'link_visualization';

type ReportsActionType = 'print_auto_theft';

type ActionType = ExceptionActionType | MutationActionType | ProfileActionType |
    AlertsActionType | SharedActionType | GroupsActionType | SuspectsActionType |
    VehiclesActionType | UsersActionType | ReportsActionType;

const GA_MEASUREMENT_ID = process.env.REACT_APP_GTAG_UA;

export function analyticsTrackEvent(category: CategoryType, action: ActionType, label?: string, value?: number): void {
    if (typeof window.gtag === 'function' && getWorkspaceName() !== 'Treuth') {
        gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    }
};

export function analyticsTrackException(description: string): void {
    analyticsTrackEvent('exception', 'graphql_error', description);
};

export function analyticsTrackMutation(action: MutationActionType, label?: string, value?: number): void {
    analyticsTrackEvent('mutation', action, label, value);
};

export function analyticsTrackProfile(action: ProfileActionType, label?: string, value?: number): void {
    analyticsTrackEvent('profile', action, label, value);
};

export function analyticsTrackAlerts(action: AlertsActionType | SharedActionType, label?: string, value?: number): void {
    analyticsTrackEvent('alerts', action, label, value);
};

export function analyticsTrackGroups(action: GroupsActionType | SharedActionType, label?: string, value?: number): void {
    analyticsTrackEvent('groups', action, label, value);
};

export function analyticsTrackSuspects(action: SuspectsActionType | SharedActionType, label?: string, value?: number): void {
    analyticsTrackEvent('suspects', action, label, value);
};

export function analyticsTrackUsers(action: UsersActionType | SharedActionType, label?: string, value?: number): void {
    analyticsTrackEvent('users', action, label, value);
};

export function analyticsTrackVehicles(action: VehiclesActionType | SharedActionType, label?: string, value?: number): void {
    analyticsTrackEvent('vehicles', action, label, value);
};

export function analyticsTrackReports(action: ReportsActionType, label?: string, value?: number): void {
    analyticsTrackEvent('reports', action, label, value);
};

export function analyticsPageView(path: string, name: string): void {
    if (typeof window.gtag === 'function' && getWorkspaceName() !== 'Treuth') {
        gtag('event', 'page_view', {
            'page_path': path,
            'page_title': name,
            'send_to': GA_MEASUREMENT_ID
        });
    }
};
