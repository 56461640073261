import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { RouterContext } from '../../routes/store/route-store';

const useStyles = makeStyles((theme) => ({
  back: {
    marginLeft: -18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  backIcon: {
    marginLeft: 5,
    marginRight: -5,
  },
}));

type PreviousButtonProps = {
  previousPath?: string,
};

function PreviousButton({ previousPath }: PreviousButtonProps) {
  const { state, dispatch } = useContext(RouterContext);
  const history = useHistory();
  const classes = useStyles();

  const goToPreviousPath = () => {
    const newRoute = state.route.from && state.route.to != state.route.from ? state.route.from : previousPath;
    if (newRoute) {
      dispatch({ type: 'CHANGE_ROUTE', payload: { ...state.route, usedBack: true } });
      history.push(newRoute, { replace: true });
    }
  }

  if (!previousPath) {
    return <></>;
  }

  return (
    <IconButton color="inherit" onClick={goToPreviousPath} className={classes.back}>
      <ArrowBackIosIcon className={classes.backIcon} />
    </IconButton>
  );
}

export default PreviousButton;
