import { gql, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { RELOAD_TIME } from '../../../../../shared/data/constants';
import { AppLinkListItem } from '../../../../../shared/display/list/LinkList';
import { WorkspacesQuery, WorkspacesQueryVariables } from './__generated__/WorkspacesQuery';

const WORKSPACES_QUERY = gql`
query WorkspacesQuery($query: String) {
  organizations(query: $query) {
    id,
    name,
  },
},
`;

function useWorkspacesQuery() {
  const { data, loading, error } = useQuery<WorkspacesQuery, WorkspacesQueryVariables>(
    WORKSPACES_QUERY,
    {
      variables: {},
    },
  );
  const workspaces: AppLinkListItem[] = orderBy((data?.organizations || []), ['name'])
    .map(organization => {
      return {
        id: organization.id,
        href: `/workspaces`,
        title: organization.name,
      };
    });
  const fetching = loading || data === undefined;

  return {
    error,
    workspaces,
    fetching
  };
};

export default useWorkspacesQuery;
