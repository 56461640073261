import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routeReducer, { RouteAction } from './route-reducer';

export type Route = {
  to?: string,
  from?: string,
  usedBack?: boolean,
};

export type RouteState = {
  route: Route,
};

function getInitialLayoutState(): RouteState {
  return {
    route: {},
  };
};

type Context = {
  state: RouteState,
  dispatch: React.Dispatch<RouteAction>,
}

export const RouterContext = React.createContext({} as Context);

type RouterProviderProps = {
  children: React.ReactNode,
};

const RouterProvider = ({ children }: RouterProviderProps) => {
  const location = useLocation();
  const [state, dispatch] = React.useReducer(routeReducer, getInitialLayoutState());

  useEffect(() => {
    dispatch({
      payload: { to: location.pathname, from: state.route.usedBack == true ? undefined : state.route.to, usedBack: false },
      type: 'CHANGE_ROUTE',
    });
  }, [location]);

  return (
    <RouterContext.Provider value={{ state, dispatch }} >
      {children}
    </RouterContext.Provider>
  )
};

export default RouterProvider;
