import alertDetailsPrintApolloClient from '../../pages/alertPrint/alertDetailsPrintApolloClient';
import alertsContentApolloClient from '../../pages/alerts/content/alertsContentApolloClient';
import alertsMapApolloClient from '../../pages/alerts/content/map/alertsMapApolloClient';
import alertDetailsApolloClient from '../../pages/alerts/details/alertDetailsApolloClient';
import alertDetailsMediaApolloClient from '../../pages/alerts/details/alertDetailsMediaApolloClient';
import dashboardMapApolloClient from '../../pages/dashboard/recentAlertsLookback/dashboardMapApolloClient';
import groupsContentApolloClient from '../../pages/groups/content/groupsContentApolloClient';
import groupDetailsApolloClient from '../../pages/groups/details/groupDetailsApolloClient';
import autoTheftReportApolloClient from '../../pages/reports/autoTheft/content/autoTheftReportApolloClient';
import suspectsContentApolloClient from '../../pages/suspects/content/suspectsContentApolloClient';
import suspectDetailsApolloClient from '../../pages/suspects/details/suspectDetailsApolloClient';
import suspectDetailsMediaApolloClient from '../../pages/suspects/details/suspectDetailsMediaApolloClient';
import usersContentApolloClient from '../../pages/users/content/usersContentApolloClient';
import vehiclesContentApolloClient from '../../pages/vehicles/content/vehiclesContentApolloClient';
import vehicleDetailsApolloClient from '../../pages/vehicles/details/vehicleDetailsApolloClient';
import vehicleDetailsMediaApolloClient from '../../pages/vehicles/details/vehicleDetailsMediaApolloClient';
import workspacesContentApolloClient from '../../pages/workspaces/content/workspacesContentApolloClient';
import mobileWorkspacesDetailsApolloClient from '../../pages/workspaces/details/cards/mobile/mobileWorkspacesDetailsApolloClient';
import workspaceDetailsApolloClient from '../../pages/workspaces/details/workspaceDetailsApolloClient';
import apolloClient, { dashboardApolloClient, usersApolloClient } from '../../shared/data/apolloClient';
import { analyticsTrackProfile } from '../../shared/utils/googleAnalytics';
import { LoginFormQuery } from '../login/__generated__/LoginFormQuery';
import { AuthAction } from './auth-reducer';

export function authLogout(dispatch: React.Dispatch<AuthAction>) {
  analyticsTrackProfile('logout');
  dispatch({ type: 'LOGOUT' });
  window.location.href = '/login';
};

export function authLogin(data: LoginFormQuery, dispatch: React.Dispatch<AuthAction>) {
  analyticsTrackProfile('login');
  Promise.all([
    alertsContentApolloClient.clearStore(),
    dashboardMapApolloClient.clearStore(),
    alertsMapApolloClient.clearStore(),
    alertDetailsApolloClient.clearStore(),
    alertDetailsPrintApolloClient.clearStore(),
    alertDetailsMediaApolloClient.clearStore(),
    groupsContentApolloClient.clearStore(),
    groupDetailsApolloClient.clearStore(),
    suspectsContentApolloClient.clearStore(),
    suspectDetailsApolloClient.clearStore(),
    suspectDetailsMediaApolloClient.clearStore(),
    usersContentApolloClient.clearStore(),
    vehiclesContentApolloClient.clearStore(),
    vehicleDetailsApolloClient.clearStore(),
    vehicleDetailsMediaApolloClient.clearStore(),
    workspacesContentApolloClient.clearStore(),
    workspacesContentApolloClient.clearStore(),
    workspaceDetailsApolloClient.clearStore(),
    mobileWorkspacesDetailsApolloClient.clearStore(),
    dashboardApolloClient.clearStore(),
    usersApolloClient.clearStore(),
    apolloClient.clearStore(),
    autoTheftReportApolloClient.clearStore(),
  ])
    .then(() => {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: data.login.user,
          accessToken: data.login.accessToken,
          refreshToken: data.login.refreshToken.token,
        }
      });
      window.location.href = '/';
    });
};
