import React from 'react';
import { ApolloError } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { AppValue } from '../../data/types';
import AppParagraphTitle from '../typography/ParagraphTitle';
import AppSelect from './Select';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    width: '100%',
  },
  text: {
    flexGrow: 1,
  },
}));

export type SelectProps = {
  onChange: (value: AppValue | null) => void,
  value?: AppValue | null,
  placeholder?: string,
};

type SelectLoaderProps = {
  name: string,
  loading: boolean,
  error?: ApolloError,
  options: AppValue[],
  required?: boolean,
};

function AppSelectLoader({ name, loading, error, options, required, onChange, value, placeholder }: SelectLoaderProps & SelectProps) {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loader}>
        <span className={classes.text}>Loading {name}...</span>
        <CircularProgress size={20} />
      </div>
    );
  }
  if (error) {
    return <AppParagraphTitle text={`Unable to load ${name}.`} />;
  }
  if (options.length === 0) {
    return <AppParagraphTitle text={`No ${name} are available.`} />;
  }
  return (
    <AppSelect
      name={name}
      options={options}
      value={value || null}
      required={required}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

export default AppSelectLoader;
