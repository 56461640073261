import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.scss'
import 'typeface-roboto';

if (/MSIE|Trident/.test(window.navigator.userAgent)) {
  document.body.classList.add('old-ie');
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
