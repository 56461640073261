import React from 'react';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AppParagraph from '../../../../shared/display/typography/Paragraph';
import AppCardTitle from '../../../../shared/display/typography/CardTitle';
import AppPhoneNumber from '../../../../shared/display/phone/PhoneNumber';
import { PhoneNumberDetails } from '../../../../shared/display/phone/__generated__/PhoneNumberDetails';
import AppAddressDisplay from '../../../../shared/display/form/AddressDisplay';
import { AppAddress } from '../../../../shared/display/form/PlacesAutocomplete';

type ContactInfoType = 'phone' | 'email' | 'address';

type ContactInfoProps = {
  name?: string,
  value: string,
  type?: ContactInfoType,
  phone?: PhoneNumberDetails | null,
  address?: AppAddress | null,
  onEditClick?: () => void,
  disableDivider?: boolean,
};

function ContactInfo({ name, value, type, phone, address, onEditClick, disableDivider }: ContactInfoProps) {
  let title = null;
  switch (type) {
    case 'email':
      title = (
        <Link href={`mailto:${value}`}>
          <AppCardTitle text={value} />
        </Link>
      );
      break;
    case 'phone':
      title = phone ? (
        <AppPhoneNumber {...phone} />
      ) : 'None';
      break;
    case 'address':
      title = address ? (
        <AppAddressDisplay {...address} />
      ) : 'None';
      break;
    default:
      title = <AppCardTitle text={value} />;
      break;
  }
  return (
    <>
      <ListItem>
        <ListItemText>
          {name &&
            <AppParagraph text={name} />
          }
          {title}
        </ListItemText>
        {onEditClick &&
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        }
      </ListItem>
      {!disableDivider && <Divider />}
    </>
  );
}

export default ContactInfo;
