import { gql, useQuery } from '@apollo/client';
import { WorkspaceSuspectsQuery, WorkspaceSuspectsQueryVariables } from './__generated__/WorkspaceSuspectsQuery';
import useSuspectsListQuery, { suspectToListLinkItem } from '../../../shared/suspects/suspectsListQuery';
import { AppLinkListItem } from '../../../../shared/display/list/LinkList';
import AppTreuthUpload from '../../../../shared/display/media/TreuthUpload';

useSuspectsQuery.fragments = {
  details: gql`
    fragment SuspectDetails on Suspect {
      id,
      firstName,
      middleName,
      lastName,
      address,
      knownOffenderStatus,
      description,
      sex,
      race
      createdDate,
      description,
      dateOfBirth,
      organization {
        id,
        autoTheft,
        graph,
      },
    }
    `,
}

const WORKSPACE_SUSPECTS_QUERY = gql`
query WorkspaceSuspectsQuery($workspaceId: Int!, $cursor: String) {
  organization(id: $workspaceId) {
    id,
    suspects(first: 6, after: $cursor, orderBy: { createdDate: DESC} ) @connection(key: "organization") {
      edges {
        node {
          ...SuspectDetails,
          attachments(first: 1, orderBy: { createdDate: DESC }, where: { isImage: true}) {
            edges {
              node {
                id,
                upload {
                  url,
                },
              },
            },
          },
        }
      }
      pageInfo {
        endCursor,
        hasNextPage,
      },
      aggregate {
        count,
      },
    },
  }
},
${useSuspectsQuery.fragments.details},
`;

function useSuspectsQuery(workspaceId: number) {
  const { data, loading, error, fetchMore } = useQuery<WorkspaceSuspectsQuery, WorkspaceSuspectsQueryVariables>(
    WORKSPACE_SUSPECTS_QUERY,
    {
      variables: { workspaceId },
    },
  );
  const suspects: AppLinkListItem[] = data?.organization?.suspects?.edges.map(edge => suspectToListLinkItem(edge.node, edge.node.attachments.edges.length > 0 ? edge.node.attachments.edges[0].node.upload.url : undefined)) || [];
  const count = data?.organization.suspects.aggregate.count || 0;
  const hasNextPage = data?.organization.suspects.pageInfo.hasNextPage || false;
  const fetching = loading || !data;

  const loadMore = () => {
    fetchMore({
      variables: {
        cursor: data?.organization.suspects.pageInfo.endCursor,
      },
    })
  };

  return {
    count,
    suspects,
    fetching,
    error,
    hasNextPage,
    loadMore
  };
}

export default useSuspectsQuery;
